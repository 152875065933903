import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import TableGridX from '../../components/tableGridX';
import { IPersonalNotification } from '../../types/notification';
import useTranslationForTableGridXConfig from '../../hooks/useTranslationForTableGridX';
import {
  DeletedNotificationsListingConfig,
  formatDayAndMonth,
  handleTypeTag,
  notificationsListingConfig,
  starredNotificationsListingConfig,
} from '../../components/material-ui/tablesConfig/notificationsListingConfig';
import WithModal from '../../components/modal/widthModal';
import Modal from '../../components/modal';
import { http } from '../../libs/axios';
import useLocale from '../../hooks/useLocale';
import { Button } from '@mui/material';
import RemoveIcon from '../../features/icons/removeIcon';
import { toast } from 'react-toastify';
import { mutate } from 'swr';
import FieldValueToDisplay from '../../components/common/fieldValueToDisplay';
import { useTranslation } from 'react-i18next';
import { GridColumns, GridValidRowModel } from '@mui/x-data-grid';
import { ApiListing } from '../../types/api';
import { confirmDelete } from '../properties/utils';

type Props = {
  as: string;
  itemsPerPage: number;
  data: IPersonalNotification[];
  handlePage: Dispatch<SetStateAction<number>>;
  page: number;
  loadingItemsToDisplay: boolean;
  autoHeight?: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  notificationsList?: ApiListing<IPersonalNotification>;
};

const NotificationsList = ({
  data,

  handlePage,
  page,
  loadingItemsToDisplay,
  autoHeight,
  setIsLoading,
  notificationsList,
  as,
}: Props) => {
  const { locale } = useLocale();
  const { t } = useTranslation('notificationsPage');

  const [finalClickInfo, setFinalClickInfo] = useState<any>({});
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [notificationConfig, setNotificationConfig] = useState<any>(
    notificationsListingConfig
  );
  const notificationsListingColumns = useTranslationForTableGridXConfig([
    notificationConfig,
    'notificationsPage',
  ]) as GridColumns<GridValidRowModel>;
  useEffect(() => {
    if (as === 'deletedList') {
      setNotificationConfig(DeletedNotificationsListingConfig);
    } else if (as === 'all') {
      setNotificationConfig(notificationsListingConfig);
    } else {
      setNotificationConfig(starredNotificationsListingConfig);
    }
  }, [as]);
  const handleOnCellClick = async (id: number) => {
    setIsLoading(true);

    await http.get('/personal_notifications/' + id).then((res) => {
      setFinalClickInfo(res?.data);
      setIsModalOpen(!isModalOpen);
    });
    await http.patch('/personal_notifications/' + id, { isRead: true });
    mutate('/personal_notifications?isDeleted=false');
    setIsLoading(false);
  };

  const handleDeleteSingleNotification = async (id: number) => {
    setIsLoading(true);
    await http
      .patch('/personal_notifications/' + id, {
        isDeleted: true,
        isStarred: false,
      })
      .then((res) => {
        if (res.status === 200) {
          toast.success(
            t('notifications_has_been_deleted_successfully') as string
          );
          setIsModalOpen(false);
        }
      });
    mutate('/personal_notifications?isDeleted=false');
    setIsLoading(false);
  };
  return (
    <div>
      <TableGridX
        disableSelectionOnClick={true}
        disableColumnSelector={true}
        rows={data}
        columns={notificationsListingColumns}
        autoPageSize
        autoHeight={autoHeight}
        pageSize={10}
        loading={loadingItemsToDisplay}
        page={page - 1}
        paginationMode="server"
        rowCount={notificationsList?.['hydra:totalItems'] || 0}
        onPageChange={(page: number) => {
          handlePage(page + 1);
        }}
        rowsPerPageOptions={[10]}
        onCellClick={({ id, field }) => {
          if (field === 'notification') {
            handleOnCellClick(Number(id));
          }
        }}
      />
      <Modal
        boxClassName="py-2"
        CloseIconStyle={{
          cursor: 'pointer',
          position: 'absolute',
          top: 0,
          right: 0,
        }}
        isOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
        openModal={() => setIsModalOpen(true)}
        buttonProps={{
          title: ' ',
          className: 'pr-6 text-sm leading-4 text-gray-900',
        }}
        withButton={false}
        closeIconSize="small"
        title={null}
        withTitleDivider={false}
      >
        {finalClickInfo?.type && (
          <>
            <div className="grid grid-cols-1 gap-9 mb-16">
              {handleTypeTag(
                finalClickInfo?.type?.translations[locale]?.name ||
                  finalClickInfo?.type?.keyname,
                finalClickInfo?.type?.keyname
              )}
              <div className="flex flex-col gap-2">
                <p className="text-2xl leading-7">
                  {finalClickInfo?.translations[`${locale}`]?.title || ''}
                </p>
                <p className="flex text-xs leading-4">
                  <span> {formatDayAndMonth(finalClickInfo?.date)}, </span>
                  <span> {finalClickInfo?.date.slice(0, 4)}</span>
                </p>
              </div>
              <p className="text-base leading-5">
                <FieldValueToDisplay
                  fieldValue={
                    finalClickInfo?.translations[`${locale}`]?.content
                  }
                  defaultValue={''}
                />
              </p>
            </div>
            <div className="flex justify-between items-center">
              {as !== 'deletedList' ? (
                <Button
                  color="error"
                  variant="outlined"
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  onClick={() =>
                    confirmDelete(() =>
                      handleDeleteSingleNotification(finalClickInfo?.id)
                    )
                  }
                  startIcon={<RemoveIcon color="#F50057" />}
                >
                  <span className="!text-[#F50057] pt-1">{t('Deleted')}</span>
                </Button>
              ) : (
                <div></div>
              )}
              <Button
                onClick={() => setIsModalOpen(false)}
                className="add_new_property"
                variant="contained"
                sx={{ background: 'background.default' }}
              >
                {'Ok'}
              </Button>
            </div>
          </>
        )}
      </Modal>
    </div>
  );
};

export default WithModal(NotificationsList);
