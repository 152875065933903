import { Box, CircularProgress, Fade, Modal, Tooltip } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import CloseIcon from '../../../features/icons/closeIcon';
import {
  getEmailContext,
  listButton,
} from '../../properties/propertiesListing/viewSentEmails';
import { useEffect, useState } from 'react';
import { formatDateAndTime } from '../../../helpers/utils';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { ApiListing, IMedia } from '../../../types/api';
import { toast } from 'react-toastify';
import { http } from '../../../libs/axios';
import PermissionIcon from '../../../features/icons/permissionIcon';
import { Link } from 'react-router-dom';
import useLocale from '../../../hooks/useLocale';
import { IPropertyApi } from '../../../types/property';

interface Props {
  title: string;
  openModal: boolean;
  onClose: () => void;
}

interface Contact {
  id: number;
  fullname: string;
}

interface EmailData {
  id: number;
  emailContext: number;
  sentAt: string;
  contact: Contact;
  properties: IPropertyApi[];
}

const SentEmailsPopup = (props: Props) => {
  const { openModal, onClose, title } = props;
  const { locale } = useLocale();
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);

  const { t } = useTranslation('propertiesPage');
  const [rows, setRows] = useState<EmailData[]>([]);
  const [apiParams, setApiParams] = useState<string>('');
  const [totalRows, setTotalRows] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const res = await http.get<ApiListing<EmailData>>(
          `/contact_emails?${apiParams}&page=${page}&itemsPerPage=${5}`
        );
        const dataWithIds = res.data['hydra:member'].map(
          (row: EmailData, index: number) => ({
            ...row,
            id: index,
          })
        );
        setRows(dataWithIds);
        setTotalRows(res.data['hydra:totalItems']);
      } catch (error) {
        toast.error(`${t('something_wrong_try_again_later')}`, {
          position: 'bottom-center',
          autoClose: 3000,
        });
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [apiParams, page]);

  useEffect(() => {
    const newApiParams = activeIndex ? `emailContext=${activeIndex}` : '';
    if (newApiParams !== apiParams) {
      setApiParams(newApiParams);
    }
  }, [activeIndex]);

  const findImagesProperty = (item: EmailData): string | undefined => {
    if (Array.isArray(item?.properties[0]?.media)) {
      return undefined;
    }

    const images = item?.properties[0]?.media.images;

    if (images && images.length > 0) {
      const mainImage = images.find((elem: IMedia) => elem.isMain);
      return mainImage ? mainImage.url.thumbnail : images[0]?.url.thumbnail;
    }

    return undefined;
  };

  const columnsEmails: GridColDef<EmailData>[] = [
    {
      field: 'contactName',
      headerName: 'contact_Name',
      headerClassName: '!pl-5',
      cellClassName: '!pl-6',
      width: 270,
      sortable: false,
      renderHeader: () => (
        <span className="leading-5 font-medium text-sm font-[Inter]">
          {t('contact_Name')}
        </span>
      ),
      renderCell: ({ row }) => {
        return (
          <div>
            {' '}
            <Link
              to={`/contacts/${row.contact.id}/contact-information`}
              id={`contacts_${row.contact.id}`}
            >
              {row.contact.fullname}
            </Link>
          </div>
        );
      },
    },
    {
      field: 'emailSentAt',
      headerName: 'Date',
      width: 250,
      sortable: false,
      renderHeader: () => (
        <span className="leading-5 font-medium text-sm font-[Inter]">
          {t('Date')}
        </span>
      ),
      renderCell: ({ row }) => {
        return <span>{formatDateAndTime(row.sentAt)}</span>;
      },
    },
    {
      field: 'generatedBy',
      headerName: 'Type',
      width: 200,
      sortable: false,
      renderHeader: () => (
        <span className="leading-5 font-medium text-sm font-[Inter]">
          {t('Type')}
        </span>
      ),
      renderCell: ({ row }) => {
        return <div>{t(getEmailContext(row.emailContext))}</div>;
      },
    },
    {
      field: 'Reference',
      headerClassName: ' hideRightSeparator !pl-3',
      cellClassName: '!pl-3 !pt-1',
      width: 350,
      sortable: false,
      renderHeader: () => (
        <Box className="leading-5 flex gap-2 Inter font-medium text-sm pl-2 py-5 text-[#030814]">
          <span>{t('common:Property_title')}</span>
          <span>/</span>

          <span>{t('common:Reference')} </span>
        </Box>
      ),
      renderCell: ({ row }) => {
        const property = row.properties[0];
        const multipleProperties = row.properties.length > 1;
        return (
          <div className="w-full flex flex-wrap h-full">
            {!multipleProperties ? (
              <>
                <Link
                  to={`/properties/${property?.id}`}
                  id={`img_property_${property?.id}`}
                  className="block  w-[100%] h-full"
                >
                  <div className="w-full flex flex-wrap h-full">
                    <div className="w-[30%] h-full">
                      <div className="h-full max-w-[80%] min-w-[80%]">
                        <img
                          className="w-full h-full object-cover"
                          src={
                            findImagesProperty(row) || '/img/default_image.jpg'
                          }
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="w-[70%] flex flex-col justify-around">
                      <Tooltip
                        title={property?.translations?.[locale]?.title}
                        arrow
                        placement="top"
                      >
                        <span className="block truncate">
                          {property?.translations?.[locale]?.title}
                        </span>
                      </Tooltip>

                      {property?.agencyReference ? (
                        <Tooltip
                          title={`${t('Reference_Agency')}: ${
                            property?.agencyReference
                          }`}
                          arrow
                          placement="top"
                        >
                          <div
                            className="flex gap-1 py-1 px-1 rounded-md items-center min-w-[135px] max-w-[135px]"
                            style={{ border: '1px solid #00bfff' }}
                          >
                            <PermissionIcon
                              className="w-[25px] col-span-1"
                              color="#00bfff"
                            />
                            <span className="text-[#00bfff] block truncate inter leading-4 font-normal text-sm">
                              {property?.agencyReference}
                            </span>
                          </div>
                        </Tooltip>
                      ) : (
                        <div>
                          <div
                            className="flex gap-1 py-1 px-1 mb-[6px] rounded-md items-center min-w-[135px] max-w-[135px]"
                            style={{ border: '1px solid #3446B9 ' }}
                          >
                            <PermissionIcon
                              className=" col-span-1"
                              color="#3446B9"
                            />{' '}
                            <span className="text-blue inter leading-4 font-normal text-sm">
                              {property?.reference}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </Link>
              </>
            ) : (
              <div className="w-full grid grid-cols-3 gap-1">
                {row.properties.map((prop, index) =>
                  prop?.agencyReference ? (
                    <Tooltip
                      key={index}
                      title={`${t('Reference_Agency')}: ${
                        prop?.agencyReference
                      }`}
                      arrow
                      placement="top"
                    >
                      <Link
                        to={`/properties/${prop?.id}`}
                        id={`property_${prop?.id}`}
                      >
                        <div
                          className="flex gap-1 py-1 px-1 my-[2px] rounded-md items-center min-w-[135px] max-w-[135px]"
                          style={{ border: '1px solid #00bfff' }}
                        >
                          <PermissionIcon
                            className="w-[25px] col-span-1"
                            color="#00bfff"
                          />
                          <span className="text-[#00bfff] block truncate inter leading-4 font-normal text-sm">
                            {prop?.agencyReference}
                          </span>
                        </div>
                      </Link>
                    </Tooltip>
                  ) : (
                    <Link
                      key={index}
                      to={`/properties/${prop?.id}`}
                      id={`property_${prop?.id}`}
                    >
                      <div>
                        <div
                          className="flex gap-1 py-1 px-1 my-[2px] rounded-md items-center min-w-[135px] max-w-[135px]"
                          style={{ border: '1px solid #3446B9 ' }}
                        >
                          <PermissionIcon
                            className=" col-span-1"
                            color="#3446B9"
                          />{' '}
                          <span className="text-blue inter leading-4 font-normal text-sm">
                            {prop?.reference}
                          </span>
                        </div>
                      </div>
                    </Link>
                  )
                )}
              </div>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <Modal
      open={openModal}
      onClose={onClose}
      classes={{ root: 'grid place-items-center' }}
    >
      <ClickAwayListener onClickAway={onClose}>
        <Fade in={openModal}>
          <Box
            sx={{
              maxHeight: '90vh',
              overflowY: 'auto',
              overflowX: 'hidden',
              width: {
                xs: '85%',
              },
              bgcolor: '#FBFBFD',
              boxShadow: 24,
              '&::-webkit-scrollbar': {
                width: '10px',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#BBC0C4',
                borderRadius: 8,
              },
              '&::-webkit-scrollbar-thumb:hover': {
                backgroundColor: '#888',
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: '#f1f1f1',
              },
            }}
          >
            <div
              className={
                'px-8 pb-6 pt-4 flex flex-col h-[87vh] min-h-[500px] relative'
              }
            >
              <div className="flex justify-between !items-start w-full">
                <p className={'mb-4 text-sm sm:text-2xl'}>{t(title)} </p>
              </div>
              <button
                onClick={onClose}
                className={
                  'absolute right-5 top-5 md:right-8 md:top-8 opacity-60 transition-opacity duration-300 hover:opacity-80'
                }
              >
                <CloseIcon />
              </button>
              <Box sx={{ width: '100%' }}>
                <Box
                  component="div"
                  sx={{
                    '&::-webkit-scrollbar': {
                      height: '10px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: '#BBC0C4',
                      borderRadius: 8,
                    },
                    '&::-webkit-scrollbar-thumb:hover': {
                      backgroundColor: '#888',
                    },
                    '&::-webkit-scrollbar-track': {
                      backgroundColor: '#f1f1f1',
                    },
                  }}
                  className="mb-8 flex gap-3 overflow-x-auto"
                >
                  {listButton.map(({ label, id }) => (
                    <div
                      onClick={() => {
                        setActiveIndex(id);
                      }}
                      className={classNames(
                        'p-2 cursor-pointer flex justify-center items-center h-[35px] border border-#E6E8EC rounded-[4px] text-xs sm:text-sm font-medium',
                        {
                          'bg-[#3446B9] text-white': activeIndex === id,
                          'bg-gray-200': activeIndex !== id,
                          'w-[60px]': id === 0,
                        }
                      )}
                      key={id}
                    >
                      <p>{t(label)}</p>
                    </div>
                  ))}
                </Box>
                <div>
                  {isLoading ? (
                    <div className="min-h-[300px]">
                      <span className="flex justify-center items-center h-[250px]">
                        <CircularProgress disableShrink />
                      </span>
                    </div>
                  ) : (
                    <DataGrid
                      sx={{
                        '& .hideRightSeparator > .MuiDataGrid-columnSeparator':
                          {
                            display: 'none',
                          },
                        '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
                          height: 10,
                        },
                        '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb':
                          {
                            backgroundColor: '#BBC0C4',
                            borderRadius: 8,
                          },
                        '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover':
                          {
                            backgroundColor: '#888',
                          },
                        '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track':
                          {
                            backgroundColor: '#f1f1f1',
                          },
                      }}
                      rows={rows}
                      columns={columnsEmails}
                      autoHeight={true}
                      loading={!rows}
                      disableColumnMenu
                      disableSelectionOnClick
                      autoPageSize
                      page={page - 1}
                      paginationMode="server"
                      rowCount={totalRows || 0}
                      onPageChange={(page: number) => {
                        setPage(page + 1);
                      }}
                      pageSize={5}
                      rowsPerPageOptions={[5]}
                      rowHeight={80}
                    />
                  )}
                </div>
              </Box>
            </div>
          </Box>
        </Fade>
      </ClickAwayListener>
    </Modal>
  );
};

export default SentEmailsPopup;
