import { Box, Button, Fade } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import CheckIcon from '@mui/icons-material/Check';
import Modal from '../../../components/modal';
import { useTranslation } from 'react-i18next';
import { http } from '../../../libs/axios';

type Props = { openModal: boolean; setOpenModal: (value: boolean) => void };

export default function LogOutModal({ openModal, setOpenModal }: Props) {
  const { t } = useTranslation('common');
  const onClickAway = () => {
    setOpenModal(false);
  };

  const onSubmit = async () => {
    await http.post('/logout?fromAll=true');
    setOpenModal(false);
  };

  return (
    <Modal
      withButton={false}
      isOpen={openModal}
      closeModal={() => setOpenModal(false)}
      openModal={() => setOpenModal(true)}
      sx={{
        maxHeight: '100vh',
      }}
      width={{
        md: '55%',
        lg: '40%',
      }}
      boxClassName="border rounded-0 !py-0"
      title={
        <span className="text-sm sm:text-lg font-medium Inter !pb-2">
          {t('Disconnect_all_other_devices')}
        </span>
      }
      buttonProps={{
        title: 'Choose role',
      }}
    >
      <ClickAwayListener onClickAway={onClickAway}>
        <Fade in={openModal}>
          <Box sx={{ textAlign: 'center' }}>
            <span className="text-sm sm:text-base font-medium Inter !pb-2 text-center">
              {t('Do_you_confirm_logging_out_from_all_session_on_all_devices')}
            </span>
            <Box className="w-full flex justify-around sm:px-16 py-6">
              <Button
                sx={{ width: '85px' }}
                onClick={onSubmit}
                variant="contained"
                disableElevation
                startIcon={<CheckIcon fontSize="small" />}
              >
                {t('yes')}
              </Button>
              <Button
                sx={{ width: '85px' }}
                onClick={() => setOpenModal(false)}
                variant="contained"
                color="error"
                disableElevation
                startIcon={<CheckIcon fontSize="small" />}
              >
                {t('no')}
              </Button>
            </Box>
          </Box>
        </Fade>
      </ClickAwayListener>
    </Modal>
  );
}
