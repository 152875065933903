import {
  InvitationItem,
  PropertyListingItem,
  SharedPropertyListingItem,
} from '../../../../types/property';
import { Box, Fade, Modal, Tooltip } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useLocale from '../../../../hooks/useLocale';
import ChecklistIcon from '@mui/icons-material/Checklist';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PopupStepper, { Step } from '../../../../components/popupStepper';
import classNames from 'classnames';
import CheckIcon from '@mui/icons-material/Check';
import { http } from '../../../../libs/axios';
import SpinnerLoading from '../../../../features/spinner/spinnerLoading';
import { toast } from 'react-toastify';
import PopupFormSuccess from '../propertiesMatching/steps/success';

interface Property extends PropertyListingItem, SharedPropertyListingItem {}

enum Steps {
  SELECT_INVITATION,
  ACTION,
}

interface Props {
  openModal: boolean;
  setOpenModal: (value: boolean) => void;
  property: Property;
}

const InvitationModal = (props: Props) => {
  const { property, setOpenModal, openModal } = props;
  const { invitations } = property;
  const [isLoading, setIsLoading] = useState(false);
  const [selectedInvitation, setSelectedInvitation] =
    useState<InvitationItem | null>(null);
  const [selectedAction, setSelectedAction] = useState<number | null>(null);
  const [successMessage, setSuccessMessage] = useState('');
  const { t } = useTranslation();
  const { locale } = useLocale();

  const onClose = () => {
    setOpenModal(false);
    setSelectedInvitation(null);
    setSelectedAction(null);
  };

  const onSubmit = async () => {
    setIsLoading(true);
    await http
      .post(
        `/property_network_invitations/properties/${property.id}/networks/${selectedInvitation?.network.id}/agencies/${selectedInvitation?.agency.id}`,
        {
          status: selectedAction,
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setIsLoading(false);
          setSuccessMessage(
            res.data.message
              ? `${res.data.message}: ${res.data.status}`
              : 'Success'
          );
        }
      })
      .catch(() => {
        toast.error(t('common:There_is_been_an_server_error') as string);
      });
  };

  const shouldDisableNext = (step: Step | undefined) => {
    switch (step?.id) {
      case Steps.SELECT_INVITATION:
        return !selectedInvitation;
        break;
      case Steps.ACTION:
        return !selectedAction;
        break;
      default:
        return false;
    }
  };

  const onBack = (step: Step | undefined) => {
    if (step?.id === Steps.ACTION) {
      setSelectedAction(null);
    }
  };

  const content = successMessage ? (
    <PopupFormSuccess>
      <div dangerouslySetInnerHTML={{ __html: successMessage }}></div>
    </PopupFormSuccess>
  ) : (
    <PopupStepper
      onClose={onClose}
      onBack={onBack}
      disableNext={shouldDisableNext}
      steps={[
        {
          id: Steps.SELECT_INVITATION,
          Icon: ChecklistIcon,
          title: t('networks:invitation'),
          body: (
            <div>
              <p className={'text-[1.5rem] font-medium mb-8'}>
                {t('networks:select_invitation')}:
              </p>
              <div className="grid grid-cols-4 gap-4">
                {invitations
                  ?.filter((item) => item.status === 1)
                  .map((item) => {
                    const selected =
                      selectedInvitation && selectedInvitation.id === item.id;
                    return (
                      <div
                        key={item.id}
                        className={classNames(
                          'py-2 px-4 border cursor-pointer rounded',
                          {
                            'border-blue-500': selected,
                            'hover:border-gray-300': !selected,
                          }
                        )}
                        onClick={() =>
                          setSelectedInvitation(selected ? null : item)
                        }
                      >
                        <div className="flex gap-2 items-center mb-2">
                          <Tooltip
                            title={t('networks:Network')}
                            arrow
                            placement="top"
                          >
                            <p className="select-none overflow-hidden overflow-ellipsis whitespace-nowrap text-[18px] font-medium">
                              {item.network.translations[locale]?.name}
                            </p>
                          </Tooltip>
                        </div>
                        <div className="flex justify-between">
                          <div className="flex-col justify-center items-start inline-flex whitespace-nowrap overflow-hidden overflow-ellipsis">
                            <div className="text-neutral-500 text-sm font-normal leading-none capitalize pb-1">
                              {t('networks:For')}:
                            </div>
                            <div className="text-zinc-900 text-sm font-medium leading-none overflow-ellipsis mb-1">
                              {item.agency.name}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          ),
        },
        {
          id: Steps.ACTION,
          Icon: CheckCircleOutlineIcon,
          title: t('networks:action'),
          body: (
            <div className="">
              <p className={'text-[1.5rem] font-medium mb-8'}>
                {t('networks:select_action')}:
              </p>
              <div className="h-10 justify-start items-start gap-2 inline-flex">
                <div
                  onClick={() => setSelectedAction(2)}
                  className={classNames(
                    'cursor-pointer px-4 py-3 border border-green-500 rounded justify-center items-center gap-4 flex',
                    {
                      'bg-green-500 text-white': selectedAction === 2,
                      'text-green-500': selectedAction !== 2,
                    }
                  )}
                >
                  <div className="text-sm font-medium font-['Inter'] leading-none">
                    {t('networks:accept')}
                  </div>
                </div>
                <div
                  onClick={() => setSelectedAction(3)}
                  className={classNames(
                    'cursor-pointer px-4 py-3 border border-rose-600 rounded justify-center items-center gap-4 flex',
                    {
                      'bg-rose-600 text-white': selectedAction === 3,
                      'text-rose-600': selectedAction !== 3,
                    }
                  )}
                >
                  <div className="text-sm font-medium font-['Inter'] leading-none">
                    {t('networks:decline')}
                  </div>
                </div>
              </div>
            </div>
          ),
          nextButton: () => {
            return (
              <div
                onClick={onSubmit}
                className="h-[40px] rounded flex items-center justify-center p-4 gap-y-2 bg-blue cursor-pointer gap-6 text-white text-[14px] transition-colors duration-100 drop-shadow hover:bg-[rgb(36,49,129)]"
              >
                {isLoading ? (
                  <SpinnerLoading />
                ) : (
                  <>
                    <span>{t('common:Save')}</span>
                    <CheckIcon
                      sx={{
                        width: '16px',
                        height: '16px',
                      }}
                      fontSize={'small'}
                    />
                  </>
                )}
              </div>
            );
          },
        },
      ]}
    />
  );

  return (
    <Modal
      open={openModal}
      onClose={onClose}
      sx={{
        '&': {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
      }}
    >
      <ClickAwayListener onClickAway={onClose}>
        <Fade in={openModal}>
          <Box
            sx={{
              maxHeight: '90vh',
              overflowY: 'auto',
              overflowX: 'hidden',
              width: {
                xs: '80%',
              },
              borderRadius: 2,
              bgcolor: 'background.paper',
              boxShadow: 24,
            }}
          >
            {content}
          </Box>
        </Fade>
      </ClickAwayListener>
    </Modal>
  );
};

export default InvitationModal;
