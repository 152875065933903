import { toast } from 'react-toastify';
import { useState } from 'react';
import { t } from 'i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { mutate } from 'swr';

import { NameableProperty } from '../../../types/property';
import { ActionData } from '../../../types/actionData';
import { http } from '../../../libs/axios';
import { useAnchorElement } from '../../../hooks/useAnchorElement';

import RestoreIcon from '@mui/icons-material/Restore';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import VisibilityOutlined from '@mui/icons-material/VisibilityOutlined';
import PublicIcon from '@mui/icons-material/Public';
import AssessmentIcon from '@mui/icons-material/Assessment';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import HistoryIcon from '../../../features/icons/historyIcon';
import EditIcon from '../../../features/icons/editIcon';
import PDFIcon from '../../../features/icons/pdfIcon';
import MatchingIcon from '../../../features/icons/matchingIcon';
import ArchiveIcon from '@mui/icons-material/Archive';
import PropertiesHistoryModal from '../../../pages/properties/propertiesListing/propertiesHistory';

import ActionMenu from '../../common/actionMenu';
import OwnersReportPopup from '../../../pages/properties/propertiesListing/ownersReport/popup';
import PreviewLanguageMenu from './previewLanguage';
import ViewSentEmails from '../../../pages/properties/propertiesListing/viewSentEmails';
import PriceHubbleReportPopup from '../../../pages/properties/propertiesListing/priceHubbleReport/popup';
import ViewArchivedLeads from "../../../pages/properties/propertiesListing/viewArchivedLeads";

const PropertiesListingActionMenu = ({
  row,
  action,
  isList,
  propertiesCards,
  isActiveProperties,
  isEditProperty,
}: {
  row: NameableProperty;
  action?: string;
  isList?: boolean;
  propertiesCards?: boolean;
  isActiveProperties?: boolean;
  isEditProperty?: boolean;
}) => {
  const [isHistoryModalOpen, toggleHistoryModal] = useState(false);
  const [viewModalEmails, setViewModalEmails] = useState(false);
  const [viewModalPriceReport, setViewModalPriceReport] = useState(false);
  const [viewModalArchivedLeads, setViewModalArchivedLeads] = useState(false);

  const handleOpenModalEmails = () => {
    setViewModalEmails(true);
  };

  const handleOpenModalPriceReport = () => {
    setViewModalPriceReport(true);
  };

  const handleCloseModal = () => {
    toggleHistoryModal(false);
  };

  const handleHistory = () => {
    toggleHistoryModal(true);
  };

  const handleArchivedLeads = () => {
    setViewModalArchivedLeads(true);
  };

  const handleMatching = async ({
    itemsPerPage,
    page,
  }: {
    itemsPerPage: number;
    page: number;
  }) => {
    try {
      await http.get(
        `/v1/properties/${row.id}/contacts/matching${
          row.matching ? '?regenerate=true' : ''
        }`
      );
      await mutate(`/v1/properties?page=${page}&itemsPerPage=${itemsPerPage}`);

      toast.success(`${t('propertiesPage:Your_matching_is_launched')}`, {
        position: 'top-right',
        autoClose: 3000,
      });
    } catch (error) {
      toast.error(
        `${t(
          'propertiesPage:There_is_been_an_server_error_Matching_was_not_started'
        )}`,
        {
          position: 'bottom-center',
          autoClose: 5000,
        }
      );
    }
  };
  const { pathname } = useLocation();
  const isArchiveProperties = pathname?.includes('archived');
  const mainAnchor = useAnchorElement();
  const langAnchor = useAnchorElement();
  const navigate = useNavigate();
  const PageInfos = JSON.parse(
    localStorage.getItem('propertylistInfos') as string
  );
  const handleArchiveProperties = async ({
    itemsPerPage,
    page,
  }: {
    itemsPerPage: number;
    page: number;
  }) => {
    try {
      await http.patch('/v1/properties/archive', {
        toArchive: [row.id],
      });
      await mutate(`/v1/properties?page=${page}&itemsPerPage=${itemsPerPage}`);

      toast.success(t('usersPage:archived_successfully') as string, {
        position: 'bottom-center',
        autoClose: 5000,
      });
      action == 'propertyDetails' && navigate('/properties/archivedProperties');
    } catch (error) {
      toast.error(
        `${t('propertiesPage:An_error_occurred_while_archiving_properties')}`,
        {
          position: 'bottom-center',
          autoClose: 5000,
        }
      );
      console.error('Error archiving properties:', error);
    }
  };
  const handleRestoreProperties = async ({
    itemsPerPage,
    page,
  }: {
    itemsPerPage: number;
    page: number;
  }) => {
    try {
      await http.patch('/v1/properties/unarchive', {
        toArchive: [row.id],
      });
      await mutate(
        `/v1/properties/archived?page=${page}&itemsPerPage=${itemsPerPage}`
      );

      toast.success(t('propertiesPage:restore_successfully') as string, {
        position: 'bottom-center',
        autoClose: 5000,
      });
    } catch (error) {
      toast.error(
        `${t('propertiesPage:An_error_occurred_while_restore_properties')}`,
        {
          position: 'bottom-center',
          autoClose: 5000,
        }
      );
      console.error('Error archiving properties:', error);
    }
  };

  const [success_message, error_message] = [
    t('common:You_property_is_duplicated'),
    t('common:Your_property_couldnt_be_duplicated'),
  ];
  const duplicateProperty = async () => {
    await http
      .get(`/v1/properties/${row.id}/duplicate`)
      .then((result) => {
        toast.success(success_message, {
          position: 'bottom-left',
          autoClose: 3000,
        });
        navigate(
          `/properties/${result.data.idClonedProperty}/edit/general-information`
        );
      })
      .catch(() => {
        toast.error(error_message, {
          position: 'bottom-left',
          autoClose: 3000,
        });
      });
  };

  const ownersReportHash = `#owner-report?id=${row.id}`;
  const location = useLocation();
  const menuActionData: ActionData[] = [
    {
      label: 'property_history',
      icon: HistoryIcon,
      onClick: handleHistory,
      class: '!hidden md:!block',
    },
    {
      label: 'property_details',
      icon: VisibilityOutlined,
      link: `/properties/${row.id}`,
      hide: action,
    },
    {
      label: !isArchiveProperties ? 'archive' : 'restore',
      icon: !isArchiveProperties ? ArchiveIcon : RestoreIcon,
      onClick: !isArchiveProperties
        ? () => handleArchiveProperties(PageInfos)
        : () => handleRestoreProperties(PageInfos),
      class: '!hidden md:!block',
    },

    {
      label: 'View_sent_emails',
      icon: MarkEmailReadIcon,
      onClick: handleOpenModalEmails,
      class: '!hidden md:!block',
    },

    {
      label: 'common:brochures_and_files',
      icon: PDFIcon,
      link: `/properties/${row.id}/brochures`,
    },
  ];

  if(row.priceHubbleData && row.priceHubbleData.priceHubbleLimit && row.priceHubbleData.priceHubbleLimit > 0) {
    menuActionData.push({
      label: 'generate_hubble_report',
      icon: AssessmentIcon,
      onClick: handleOpenModalPriceReport,
    });
  }

  if (isActiveProperties) {
    menuActionData.splice(0, 0, {
      label: 'edit_property',
      icon: EditIcon,
      link: `/properties/${row.id}/edit/general-information`,
      hide: action,
      class: '!hidden md:!block',
    });
    menuActionData.splice(3, 0, {
      label: 'Duplicate_property',
      icon: FileCopyIcon,
      onClick: duplicateProperty,
      hide: action,
      class: '!hidden md:!block',
    });
  }

  if (isEditProperty) {
    menuActionData.push({
      label: 'Duplicate_property',
      icon: FileCopyIcon,
      onClick: duplicateProperty,
      class: '!hidden md:!block',
    });
  }

  if(isArchiveProperties) {
    menuActionData.push({
      label: 'archived_leads',
      icon: ArchiveOutlinedIcon,
      onClick: handleArchivedLeads,
      class: '!hidden md:!block',
    });
  }

  if (isActiveProperties || isEditProperty) {
    menuActionData.push(
      {
        label: 'landing_page',
        icon: PublicIcon,
        onClick: langAnchor.handleClick,
        stopClose: true,
      },
      {
        label: 'matching',
        icon: MatchingIcon,
        onClick: () => handleMatching(PageInfos),
        class: '!hidden md:!block',
      },
      {
        label: 'generate_owner_report',
        icon: AssessmentIcon,
        link: `${location.pathname}${ownersReportHash}`,
      }
    );
  }

  return (
    <>
      <PreviewLanguageMenu
        {...langAnchor}
        reference={row.reference || ''}
        handleClose={() => {
          langAnchor.handleClose();
          mainAnchor.handleClose();
        }}
      />
      <ActionMenu
        {...mainAnchor}
        action={action}
        menuAction={menuActionData}
        transPage={'propertiesPage'}
        isList={isList}
        propertiesCards={propertiesCards}
      />
      {isHistoryModalOpen && (
        <PropertiesHistoryModal
          id={Number(row.id)}
          isModalOpen={isHistoryModalOpen}
          setIsModalOpen={toggleHistoryModal}
          handleCloseModal={handleCloseModal}
          action={action}
        />
      )}
      <OwnersReportPopup property={row} hash={ownersReportHash} />
      {viewModalPriceReport && (
        <PriceHubbleReportPopup
          property={row}
          isModalOpen={viewModalPriceReport}
          setIsModalOpen={setViewModalPriceReport}
          withBathrooms={!!isEditProperty}
        />
      )}

      {viewModalEmails && (
        <ViewSentEmails
          id={Number(row.id)}
          isModalOpen={viewModalEmails}
          setIsModalOpen={setViewModalEmails}
        />
      )}

      {viewModalArchivedLeads && (
        <ViewArchivedLeads
          id={Number(row.id)}
          isModalOpen={viewModalArchivedLeads}
          setIsModalOpen={setViewModalArchivedLeads}
        />
      )}
    </>
  );
};

export default PropertiesListingActionMenu;
