import React from 'react';
import WidgetWrapper from './wrapper';
import { WidgetProps } from '../index';
import { useTranslation } from 'react-i18next';
import { formatPriceWithCurrency } from '../utils/useOffersListColumns';

const SalesWidget: React.FC<WidgetProps> = (props) => {
  const { sales } = props.data;
  const { t } = useTranslation('common');

  return (
    <WidgetWrapper title={'MySales'} linkText={'ViewMore'} link={null}>
      <div className="w-full flex-col justify-center items-start gap-8 inline-flex">
        <div className="self-stretch flex-col justify-center items-start gap-2 flex">
          <div className="self-stretch pb-2 border-b border-gray-200 justify-between gap-8 items-start inline-flex">
            <div className=" whitespace-nowrap text-zinc-900 text-sm font-normal leading-none">
              {t('TotalSoldPrice')}
            </div>
            <div className=" text-right text-zinc-900 text-sm font-medium leading-none whitespace-nowrap lg:truncate">
              {formatPriceWithCurrency(
                sales['total-sold-price'],
                sales.currency
              )}
            </div>
          </div>
          <div className="self-stretch pb-2 border-b border-gray-200 justify-between gap-8 items-start inline-flex">
            <div className="whitespace-nowrap text-zinc-900 text-sm font-normal leading-none">
              {t('AgencyCommission')}
            </div>
            <div className="grow shrink basis-0 text-right text-zinc-900 text-sm font-medium leading-none whitespace-nowrap lg:truncate">
              {formatPriceWithCurrency(
                sales['agency-commission'],
                sales.currency
              )}
            </div>
          </div>
          <div className="self-stretch pb-2 border-b border-gray-200 justify-between gap-8 items-start inline-flex">
            <div className="whitespace-nowrap text-zinc-900 text-sm font-normal leading-none">
              {t('MyCommission')}
            </div>
            <div className="grow shrink basis-0 text-right text-zinc-900 text-sm font-medium leading-none whitespace-nowrap lg:truncate">
              {formatPriceWithCurrency(sales['my-commission'], sales.currency)}
            </div>
          </div>
        </div>
      </div>
    </WidgetWrapper>
  );
};

export default SalesWidget;
