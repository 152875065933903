import LangSwitcher from './langSwitcher';
import SocialMedias from './socialMedias';
import { Box } from '@mui/material';

export default function Footer() {
  return (
    <Box
      sx={{
        color: 'text.primary',
      }}
      component="footer"
      className="flex flex-col lg:flex-row lg:gap-6 justify-between text-sm mb-3 items-center w-full"
    >
      <Box className="flex items-center sm:gap-x-20 lg:gap-x-0 xl:gap-x-20">
        <Box>© {new Date().getFullYear()} Reals. All rights reserved.</Box>
        <LangSwitcher />
      </Box>
      <SocialMedias />
      <Box>
        <Box
          component="ul"
          className="flex gap-x-2 sm:gap-x-6 text-[12px] sm:text-[0.875rem]"
        >
          <Box component="li">About us</Box>
          <Box component="li">Privacy Policy</Box>
          <Box component="li">Terms of Service</Box>
          <Box component="li">Contact Us</Box>
          <Box component="li">Help center</Box>
        </Box>
      </Box>
    </Box>
  );
}
