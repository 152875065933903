import { saveAs } from 'file-saver';
import { http } from '../../libs/axios';

export async function downloadFile({
  url,
  fileName = parseFileName(url),
}: {
  url: string;
  fileName?: string;
}) {
  const { data } = await http.get(url, {
    responseType: 'blob',
  });
  saveAs(data, fileName);
}

// example name: {process.env.REACT_APP_API_HOST}/uploads/property/368/brochures/Sun3Dec121-656da28ae88c8.pdf
export function parseFileName(name: string) {
  if (!name) return '';
  const parts = name.split('/');
  return parts[parts.length - 1];
}
