import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { WidgetPreviewProps } from '../../../../../types/brochureEditor';
import GoogleMapContainer from '../../../../../components/map/googleMapContainer';
import QrCode from '../../qrCode';
import { useAddress, useGoogleMapsLink } from '../../../lib/hooks';

const MapWidget = ({ previewSubject, widgetOptions }: WidgetPreviewProps) => {
  const { t } = useTranslation('brochureEditor');

  const address = useAddress(previewSubject);
  const googleMapsLink = useGoogleMapsLink(address);

  const withQr = widgetOptions?.withQr?.value;
  const zoom = widgetOptions?.zoom?.value;

  const lat = address?.latitude;
  const lng = address?.longitude;

  let contents: ReactNode = null;
  if (lat && lng) {
    const params = new URLSearchParams({
      size: '640x640',
      scale: '1',
      format: 'webp',
      maptype: 'roadmap',
      markers: `${lat},${lng}`,
      key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
    });
    if (zoom) {
      params.append('zoom', zoom.toString());
    }
    contents = (
      <>
        <GoogleMapContainer
          coordinates={{ lat, lng }}
          containerStyle={{ height: '100%' }}
          zoom={Number(zoom) || undefined}
        />

        {withQr && googleMapsLink && (
          <div className="absolute bottom-0 right-0 z-10">
            <QrCode value={googleMapsLink}></QrCode>
          </div>
        )}
      </>
    );
  } else {
    contents = <div className="grid place-items-center">{t('no_address')}</div>;
  }

  return <div className="relative grid">{contents}</div>;
};

export default MapWidget;
