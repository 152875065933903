import React from 'react';
import WidgetWrapper from './wrapper';
import { WidgetProps } from '../index';
import {
  calculatePercent,
  COLORS,
} from '../../properties/propertiesListing/ownersReport/report/pieChart';
import { PieChart as MinimalPieChart } from 'react-minimal-pie-chart';
import { useTranslation } from 'react-i18next';

const PipelineStatusWidget: React.FC<WidgetProps> = (props) => {
  const { data } = props;
  const statuses = data['pipelines-stats'];
  const { t } = useTranslation('common');

  const series = statuses
    ? [
        {
          value: statuses.entrenace,
          color: COLORS[0 % COLORS.length] as string,
          title: `${t('entrance_step')} (${calculatePercent(
            statuses.entrenace,
            100
          )}%)`,
        },
        {
          value: statuses.regular,
          color: COLORS[1 % COLORS.length] as string,
          title: `${t('intermediate_steps')} (${calculatePercent(
            statuses.regular,
            100
          )}%)`,
        },
        {
          value: statuses.closed,
          color: COLORS[2 % COLORS.length] as string,
          title: `${t('close_steps')} (${calculatePercent(
            statuses.closed,
            100
          )}%)`,
        },
      ]
    : [];

  return (
    <WidgetWrapper
      emptyData={Object.values(statuses).every((value) => value === 0)}
      title={'leads_progression'}
      linkText={'ViewMore'}
      link={null}
    >
      <div className={'flex flex-col justify-center w-full'}>
        <div className="w-[262px] aspect-square mx-auto mb-5">
          <MinimalPieChart data={series} lineWidth={60} startAngle={-90} />
        </div>
        <div className="justify-start items-center gap-2 inline-flex flex-wrap">
          {series.map((el) => (
            <div
              key={el.value}
              className="px-2 py-1 bg-white rounded-[32px] justify-start items-center gap-2 flex"
            >
              <div
                className={`w-[8px] h-[8px] rounded-full`}
                style={{ backgroundColor: el.color }}
              />
              <div className="text-zinc-900 text-sm font-medium font-['Inter'] md:leading-none">
                {el.title}
              </div>
            </div>
          ))}
        </div>
      </div>
    </WidgetWrapper>
  );
};

export default PipelineStatusWidget;
