import { http } from '../../../../libs/axios';
import { toast } from 'react-toastify';
import i18n from '../../../../i18n';

const terminate = (id: string | number, mutate: any) => {
  const { t } = i18n;
  http
    .patch(`/network_members/${id}`, {
      action: 'terminate',
    })
    .then(() => {
      toast.success(`${t('alerts:networks:agencyMembershipWasTerminated')}`, {
        position: 'top-right',
        autoClose: 3000,
      });
      if (mutate) {
        mutate();
      }
    })
    .catch(() => {
      toast.error(
        `${t(
          'common:Only_action_exit_is_authorized_for_this_member_Member_has_already_accepted_invitation'
        )}`,
        {
          position: 'bottom-right',
          autoClose: 3000,
        }
      );
    });
};

export default terminate;
