import React from 'react';
import WidgetWrapper from './wrapper';
import User from '../../../types/user';
import { useAuth } from '../../../hooks/useAuth';
import { formatDate } from '../../../helpers/utils';
import { WidgetProps } from '../index';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';

const getRoleName = (roles: string[]): string => {
  if (roles.includes('ROLE_ADMIN')) {
    return 'Admin';
  } else if (roles.includes('ROLE_USER')) {
    return 'User';
  } else if (roles.includes('ROLE_ADMIN_ASSISTANT')) {
    return 'Admin Assistant';
  } else if (roles.includes('ROLE_ADMIN_AGENT')) {
    return 'Admin Agent';
  } else if (roles.includes('ROLE_ADMIN_OPERATOR')) {
    return 'Admin Operator';
  } else if (roles.includes('ROLE_ASSISTANT')) {
    return 'Assistant';
  } else {
    return 'Unknown Role';
  }
};

export const getSpecificField = (
  user: User,
  field: 'name' | 'id'
): string | number | null => {
  if (user.agency) {
    return user.agency[field];
  } else if (user.department) {
    return user.department[field];
  } else if (user.company) {
    return user.company[field];
  } else {
    return null;
  }
};

const ProfileWidget: React.FC<WidgetProps> = (props) => {
  const { user } = useAuth();
  const { profile } = props.data;
  const { avatar, roles } = user;
  const { firstname, lastname, level } = profile;

  const { t } = useTranslation('common');

  return (
    <WidgetWrapper title={'MyProfile'} linkText={'MyProfile'} link={'/profile'}>
      <div className="flex-col justify-center items-start gap-8 flex w-full">
        <div className="self-stretch flex-col justify-center items-center gap-4 flex">
          {avatar ? (
            <img
              className="w-[88px] h-[88px] rounded-full object-cover"
              src={avatar}
            />
          ) : (
            <div
              className={'w-[88px] h-[88px] rounded-full border bg-gray-200'}
            />
          )}
          <div className="flex-col justify-center items-start gap-1 flex">
            <div className="w-full text-center text-zinc-900 text-2xl font-normal leading-7">
              {firstname} {lastname}
            </div>
            <div className="w-full text-center text-neutral-500 text-sm font-medium leading-none">
              {getRoleName(roles)}
              {level ? `, ${t('Level')} ${level}` : ''}
            </div>
          </div>
        </div>
        <div className="self-stretch h-14 flex-col justify-center items-start gap-2 flex">
          <div className="self-stretch pb-2 border-b border-gray-200 justify-start items-start gap-4 inline-flex">
            <div className="grow shrink basis-0 text-zinc-900 text-sm font-normal leading-none">
              {t('Agency')}
            </div>
            <Tooltip
              title={getSpecificField(user, 'name')}
              arrow
              placement="top"
            >
              <div className="grow shrink basis-0 text-zinc-900 text-sm font-medium leading-4 lg:truncate">
                {getSpecificField(user, 'name')}
              </div>
            </Tooltip>
          </div>
          <div className="self-stretch pb-2 border-b border-gray-200 justify-start items-start gap-4 inline-flex">
            <div className="grow shrink basis-0 text-zinc-900 text-sm font-normal leading-none">
              {t('MembersSince')}
            </div>
            <div className="grow shrink basis-0 text-zinc-900 text-sm font-medium leading-none">
              {formatDate(profile['member-since'])}
            </div>
          </div>
        </div>
      </div>
    </WidgetWrapper>
  );
};

export default ProfileWidget;
