import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import PersonalNotifications from './components/steps/allNotifications';
import StarredNotifications from './components/steps/starredNotifications';
import DeletedNotifications from './components/steps/deletedNotificaions';
import { useTranslation } from 'react-i18next';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function NotificationsPage() {
  const { t } = useTranslation('notificationsPage');
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box
        sx={{
          overflowX: 'auto',
          whiteSpace: 'nowrap',
          width: '100%',
          '&::-webkit-scrollbar': {
            height: '10px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#BBC0C4',
            borderRadius: '8px',
          },
          '&::-webkit-scrollbar-thumb:hover, &::-webkit-scrollbar-thumb:active':
            {
              backgroundColor: '#888',
            },
          '&::-webkit-scrollbar-track': {
            backgroundColor: '#f1f1f1',
          },
        }}
      >
        <Tabs
          sx={{
            minWidth: 'max-content',
            '& .MuiTabs-scroller': {
              overflowX: 'auto',
            },
          }}
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            sx={{ textTransform: 'capitalize' }}
            label={t('All_notifications')}
            {...a11yProps(0)}
          />
          <Tab
            sx={{ textTransform: 'capitalize' }}
            label={t('Starred')}
            {...a11yProps(1)}
          />
          <Tab
            sx={{ textTransform: 'capitalize' }}
            label={t('Deleted')}
            {...a11yProps(2)}
          />
        </Tabs>
      </Box>
      <Paper style={{ minHeight: '75vh' }}>
        <CustomTabPanel value={value} index={0}>
          <PersonalNotifications />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <StarredNotifications />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <DeletedNotifications />
        </CustomTabPanel>
      </Paper>
    </Box>
  );
}
