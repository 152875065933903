import { Dispatch, SetStateAction } from 'react';
import { listButton } from '../../../layout/globalNotifications/index';
import { useTranslation } from 'react-i18next';

type Props = {
  activeIndex: number;
  count: number | undefined;
  setActiveIndex: Dispatch<SetStateAction<number>>;
};
const NotificationFilter = ({ activeIndex, setActiveIndex, count }: Props) => {
  const { t } = useTranslation('notificationsPage');
  return (
    <>
      {listButton.map(({ label, id }) => (
        <div
          onClick={() => {
            setActiveIndex(id);
          }}
          className={`p-2 ${
            activeIndex === id && 'bg-[#3446B9] text-white'
          }  cursor-pointer flex justify-center items-center px-[5px] py-[8px] h-[38px] sm:h-[27px] border border-#E6E8EC rounded-[4px] text-xs sm:text-sm`}
          key={id}
        >
          <p>
            {t(label)}
            {id === 2 && <span>{` (${count})`}</span>}{' '}
          </p>
        </div>
      ))}
    </>
  );
};

export default NotificationFilter;
