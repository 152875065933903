import { t } from 'i18next';

export const generateHistoryDetails = (
  changes: Record<string, any>,
  locale: string,

  data: Record<string, any>
): string => {
  const changesKeysArray = Object.keys(changes);
  const historyDetails = changesKeysArray.map((elem) => {
    let from = changes[elem]?.from;
    let to = changes[elem]?.to;
    let updatedElement = elem;
    let fromDescription = '';
    let toDescription = '';
    let fromTitle = '';
    let toTitle = '';
    const tab = [];

    switch (elem) {
      case 'address':
        if (typeof changes[elem] === 'object') {
          Object.entries(
            changes[elem] as Record<string, { from: any; to: any }>
          ).forEach(([key, value]) => {
            if (key === 'zipCode') {
              from = value?.from;
              to = value?.to;
              updatedElement = 'Zip_code';
              tab.push([updatedElement, from, to]);
            } else if (key === 'translations') {
              from =
                value?.from[locale].adresse +
                ' ' +
                value?.from[locale].city +
                ' ' +
                value?.from[locale].country;
              to =
                value?.to[locale].adresse +
                ' ' +
                value?.to[locale].city +
                ' ' +
                value?.to[locale].country;
              updatedElement = 'address';
              tab.push([updatedElement, from, to]);
            }
          });
        }
        break;
      case 'department':
        from = typeof from === 'object' && from?.name ? from.name : from;
        if (to == undefined) to = 'empty';
        else to = data[elem].name;
        tab.push([updatedElement, from, to]);
        break;
      case 'mainBroker':
      case 'intermediateBroker':
        from =
          typeof from === 'object' && from !== null
            ? `${from?.firstname ?? ''} ${from?.lastname ?? ''}`.trim()
            : 'empty';

        to =
          to !== null && typeof data[elem] === 'object'
            ? `${data[elem]?.firstname ?? ''} ${
                data[elem]?.lastname ?? ''
              }`.trim()
            : 'empty';

        tab.push([updatedElement, from, to]);
        break;

      case 'status':
      case 'category':
      case 'availability':
      case 'sunlight':
      case 'state':
      case 'soundLevel':
      case 'heatingType':
      case 'energy':
      case 'mandateType':
        from =
          from.translations?.[locale]?.value ||
          from.translations?.[locale]?.name;
        to =
          data[elem]?.translations?.[locale]?.value ||
          data[elem]?.translations?.[locale]?.name;
        tab.push([updatedElement, from, to]);
        break;
      case 'media':
      case 'links3d':
      case 'videoLinks':
        from = 'Link';
        to = 'New Link';
        tab.push([updatedElement, from, to]);
        break;

      case 'promotion':
        if (typeof from === 'object' && from !== null) {
          from =
            from?.translations?.[locale]?.value ||
            from?.translations?.[locale]?.name;
        } else {
          from = 'empty';
        }

        to =
          data[elem]?.translations?.[locale]?.value ||
          data[elem]?.translations?.[locale]?.name;

        tab.push([
          updatedElement,
          from === undefined ? 'empty' : from,
          to === undefined ? 'empty' : to,
        ]);
        break;

      case 'warningField':
        if (typeof from === 'object' && from != null) {
          if (from.translations?.[locale]?.value == '') from = 'empty';
          else from = from.translations?.[locale]?.value;
        }
        if (typeof to === 'object' && to != null) {
          if (to.translations?.[locale]?.value == '') to = 'empty';
          else to = to.translations?.[locale]?.value;
        }
        if ((from == undefined && to == 'empty') || from == to) {
          return;
        }
        tab.push([updatedElement, from, to]);
        break;
      case 'translations':
        // Process 'description'
        fromDescription =
          typeof from === 'object' &&
          from != null &&
          from?.[locale]?.description !== ''
            ? from?.[locale]?.description
            : 'empty';

        toDescription =
          typeof to === 'object' &&
          to != null &&
          to?.[locale]?.description !== ''
            ? to?.[locale]?.description
            : 'empty';

        updatedElement = 'description';
        tab.push([updatedElement, fromDescription, toDescription]);

        // Process 'title'
        fromTitle =
          typeof from === 'object' &&
          from != null &&
          from?.[locale]?.title !== ''
            ? from?.[locale]?.title
            : 'empty';

        toTitle =
          typeof to === 'object' && to != null && to?.[locale]?.title !== ''
            ? to?.[locale]?.title
            : 'empty';

        updatedElement = 'title';
        tab.push([updatedElement, fromTitle, toTitle]);
        break;

      case 'type':
        from =
          from.translations?.[locale]?.value ||
          from.translations?.[locale]?.name;
        if (to == undefined) {
          return;
        }
        to =
          data[elem]?.translations?.[locale]?.value ||
          data[elem]?.translations?.[locale]?.name;
        tab.push([updatedElement, from, to]);
        break;

      case 'propertyBuy':
      case 'propertyRent':
        Object.entries(changes[elem]?.from as Record<string, any>).forEach(
          ([key, fromValue]) => {
            const toValue = changes[elem]?.to[key];
            if (fromValue != changes[elem]?.to[key]) {
              if (
                key === 'propertyCommissionRepartition' ||
                key === 'propertyCommissionRepartitionIntermediate'
              ) {
                const from =
                  Array.isArray(fromValue) && fromValue.length > 0
                    ? fromValue.map(
                        (el: any) =>
                          `Amount :${
                            el.amount == null || isNaN(el.amount)
                              ? 'empty'
                              : el.amount
                          }, Broker :${el.mainBroker}, percentage :${
                            el.percentage == null ? 'empty' : el.percentage
                          }`
                      )
                    : 'empty';
                const to =
                  Array.isArray(toValue) && toValue.length > 0
                    ? toValue.map(
                        (el: any) =>
                          `Amount :${
                            el.amount == null || isNaN(el.amount)
                              ? 'empty'
                              : el.amount
                          }, Broker :${el.mainBroker}, percentage :${
                            el.percentage == null ? 'empty' : el.percentage
                          }`
                      )
                    : 'empty';

                if (JSON.stringify(from) !== JSON.stringify(to)) {
                  tab.push([key, from, to]);
                }
              } else if (
                [
                  'saleDate',
                  'signatureDate',
                  'lastDepositDate',
                  'forwardSale',
                  'handoverOfKeys',
                ].includes(key)
              ) {
                const from =
                  fromValue != null
                    ? new Date(fromValue).toISOString()
                    : 'empty';
                const to =
                  toValue?.$d != null
                    ? new Date(toValue.$d).toISOString()
                    : 'empty';

                if (from !== to) {
                  tab.push([key, from, to]);
                }
              } else if (key === 'sellType') {
                const sellTypeMap: Record<string, string> = {
                  '1': 'direct sale',
                  '2': 'Forward sale',
                  '3': 'Sale commitment',
                };
                const from =
                  fromValue === undefined || fromValue === null
                    ? 'empty'
                    : sellTypeMap[fromValue];
                const to =
                  toValue === undefined || toValue === null
                    ? 'empty'
                    : sellTypeMap[toValue];

                tab.push([key, from, to]);
              } else if (key === 'salePriceSigned') {
                const from =
                  fromValue === undefined || fromValue === null
                    ? 'empty'
                    : fromValue;
                const to =
                  isNaN(toValue) || toValue === null ? 'empty' : toValue;

                if (toValue != undefined) {
                  tab.push([key, from, to]);
                }
              } else if (key === 'notes') {
                const from =
                  fromValue == '' || fromValue === null ? 'empty' : fromValue;
                const to =
                  toValue == '' || toValue === null ? 'empty' : toValue;

                tab.push([key, from, to]);
              } else if (fromValue !== toValue) {
                const from =
                  fromValue === undefined ||
                  fromValue === null ||
                  isNaN(fromValue)
                    ? 'empty'
                    : fromValue;
                const to =
                  toValue === undefined || toValue === null || isNaN(toValue)
                    ? 'empty'
                    : toValue;

                tab.push([key, from, to]);
              }
            }
          }
        );

        break;

      case 'rateMainBroker':
      case 'rateIntermediateBroker':
        if (isNaN(from) || null) {
          from = 'empty';
        }

        if (isNaN(to) || null) {
          to = 'empty';
        }

        tab.push([updatedElement, from, to]);
        break;
      case 'pricingRent':
      case 'pricingBuy':
        Object.entries(
          changes[elem] as Record<string, { from: any; to: any }>
        ).forEach(([key, value]) => {
          if (
            key === 'frequency' ||
            key === 'chargesIncluded' ||
            key === 'individualChargesIncluded' ||
            key === 'heatingAnnualChargesIncluded' ||
            key === 'regime' ||
            key === 'maintenanceContract'
          ) {
            from =
              value?.from != null
                ? value?.from?.translations[locale]?.name
                : value?.from;
            to = (data[elem] as any)[key]?.translations[locale]?.name;
            updatedElement = key;
            tab.push([updatedElement, from, to]);
          } else if (key === 'currency') {
            from = value?.from.value;
            to = data[elem][key]?.value;
            updatedElement = key;
            tab.push([updatedElement, from, to]);
          } else if (key === 'parkings') {
            from =
              value?.from != null
                ? value?.from.map((el: any) => {
                    return (
                      t('propertiesPage:Parking_included') +
                      ': ' +
                      el.parkingIncluded?.translations[locale]?.name +
                      ', ' +
                      t('propertiesPage:Parking_mandatory') +
                      ': ' +
                      el.parkingMandatory?.translations[locale]?.name +
                      ', ' +
                      t('propertiesPage:Parking_price') +
                      ': ' +
                      el.parkingPrice +
                      ', ' +
                      t('propertiesPage:Parking_type') +
                      ': ' +
                      el.parkingType?.translations[locale]?.name
                    );
                  })
                : value?.from;

            to = Object.values(data[elem][key]).map((el: any) => {
              return (
                t('propertiesPage:Parking_included') +
                ': ' +
                el.parkingIncluded?.translations[locale]?.name +
                ', ' +
                t('propertiesPage:Parking_mandatory') +
                ': ' +
                el.parkingMandatory?.translations[locale]?.name +
                ', ' +
                t('propertiesPage:Parking_price') +
                ': ' +
                el.parkingPrice +
                ', ' +
                t('propertiesPage:Parking_type') +
                ': ' +
                el.parkingType?.translations[locale]?.name
              );
            });
            updatedElement = key;
            tab.push([
              updatedElement,
              from.length > 0 ? from : 'empty',
              to.length > 0 ? to : 'empty',
            ]);
          } else {
            from = value?.from;
            to = value?.to;
            updatedElement = key;
            tab.push([updatedElement, from, to]);
          }
        });
        break;
      case 'buildingBlocks':
        from = from.map((el: any) => {
          return (
            t('propertiesPage:name') +
            ': ' +
            el.name +
            ', ' +
            t('propertiesPage:surface') +
            ': ' +
            el.surface +
            ', ' +
            t('propertiesPage:orientation') +
            ': ' +
            el.orientation.translations[locale].name
          );
        });
        to = data[elem].map((el: any) => {
          return (
            t('propertiesPage:name') +
            ': ' +
            el.name +
            ', ' +
            t('propertiesPage:surface') +
            ': ' +
            el.surface +
            ', ' +
            t('propertiesPage:orientation') +
            ': ' +
            el.orientation.translations[locale].name
          );
        });
        updatedElement = 'buildingBlocks';
        tab.push([
          updatedElement,
          from.length > 0 ? from : 'empty',
          to.length > 0 ? to : 'empty',
        ]);
        break;

      case 'orientations':
      case 'views':
        from = from.map((el: any) => {
          return (
            el?.translations[locale]?.name || el?.translations[locale]?.value
          );
        });
        to = Object.values(data[elem]).map((el: any) => {
          return (
            el?.translations[locale]?.name || el?.translations[locale]?.value
          );
        });
        tab.push([
          updatedElement,
          from.length > 0 ? from : 'empty',
          to.length > 0 ? to : 'empty',
        ]);
        break;
      case 'fieldValues':
        from = from.map((el: any) => {
          return (
            el?.dataField?.translations[locale]?.name ||
            el?.dataField?.translations[locale]?.value
          );
        });
        to = Object.values(data[elem]).map((el: any) => {
          return (
            el?.dataField?.translations[locale]?.name ||
            el?.dataField?.translations[locale]?.value
          );
        });
        tab.push([
          updatedElement,
          from.length > 0 ? from : 'empty',
          to.length > 0 ? to : 'empty',
        ]);
        break;
      case 'responseFieldValues':
        from = from.general_information.map((el: any) => {
          return (
            el?.dataField?.translations[locale]?.name ||
            el?.dataField?.translations[locale]?.value
          );
        });
        to = Object.values(data[elem]).map((el: any) => {
          return (
            el?.dataField?.translations[locale]?.name ||
            el?.dataField?.translations[locale]?.value
          );
        });
        tab.push([
          updatedElement,
          from.length > 0 ? from : 'empty',
          to.length > 0 ? to : 'empty',
        ]);
        break;
      case 'dataFields':
        from = from.general_information.undefined_tag.map((el: any) => {
          return (
            el?.translations[locale]?.name || el?.translations[locale]?.value
          );
        });
        to = Object.values(data[elem]).map((el: any) => {
          return (
            el?.translations[locale]?.name || el?.translations[locale]?.value
          );
        });
        tab.push([
          updatedElement,
          from.length > 0 ? from : 'empty',
          to.length > 0 ? to : 'empty',
        ]);
        break;
      case 'publishWebsite':
        Object.entries(
          changes[elem] as Record<string, { from: any; to: any }>
        ).forEach(([key, value]) => {
          if (key === 'startDate' || key === 'endDate') {
            from = value?.from ? new Date(value.from).toISOString() : null;
            to = value?.to?.$d ? new Date(value.to.$d).toISOString() : null;
            updatedElement = key;
            tab.push([updatedElement, from, to]);
          } else if (
            key === 'publishWebsite' ||
            key === 'publishHomepage' ||
            key === 'featured'
          ) {
            from = value?.from;
            to = value?.to;
            updatedElement = key;
            tab.push([updatedElement, from, to]);
          }
        });
        break;
      case 'publishGateways':
        type GatewayChange = {
          from: {
            isPublished: boolean;
            startDate: string;
            endDate: string;
            gateway?: {
              name: string;
            };
          };
          to: {
            isPublished: boolean;
            startDate: string;
            endDate: string;
            gateway?: {
              name: string;
            };
          };
        };
        Object.entries(changes[elem] as Record<string, GatewayChange>).forEach(
          ([key, value]) => {
            console.log('publishGateways', key, value);
            from =
              value.from.isPublished +
              ', ' +
              value.from.startDate +
              ', ' +
              value.from.endDate;
            to =
              value.to.isPublished +
              ', ' +
              value.to.startDate +
              ', ' +
              value.to.endDate;
            updatedElement = elem + '(' + value.from?.gateway?.name + ')';
            tab.push([updatedElement, from, to]);
          }
        );

        break;

      default:
        if (typeof from === 'object' && from != null) {
          from =
            from.translations?.[locale]?.value ||
            from.translations?.[locale]?.name ||
            from.name ||
            JSON.stringify(from);
        }
        if (typeof to === 'object' && to != null) {
          to =
            to.translations?.[locale]?.value || to.name || JSON.stringify(to);
        }
        tab.push([updatedElement, from, to]);
        break;
    }
    const tabFinal = tab.filter((elem) => elem[1] != elem[2]);
    return tabFinal;
  });

  let finalString = '';
  if (historyDetails.length > 0) {
    historyDetails.forEach((el: any) => {
      if (el != undefined) {
        el.forEach((subEl: any) => {
          finalString +=
            subEl[0].charAt(0).toUpperCase() +
            subEl[0].slice(1) +
            '/' +
            ' changedFrom <span class="text-blue-600">' +
            subEl[1] +
            '</span> to <span class="text-blue-600">' +
            subEl[2] +
            '</span>.</br>';
        });
      }
    });
  }

  return finalString;
};
