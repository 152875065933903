import React from 'react';
import { useTranslation } from 'react-i18next';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import classNames from 'classnames';

const PriceHubbleReportRequirements = ({
  validation,
}: {
  validation: Record<string, boolean>;
}) => {
  const { t } = useTranslation('propertiesPage');

  return (
    <div className="flex flex-col gap-8">
      <div>
        <p className="text-[2rem] font-medium mb-2">
          {t('Requirements_title')}.
        </p>
        <p className="text-[1rem]">{t('Requirements_subtitle')}:</p>
      </div>
      <div className={'flex flex-col divide-y'}>
        {validation ? (
          Object.entries(validation).map(([key, isValid]) => (
            <div className={'flex justify-between py-2'} key={key}>
              <div
                className={classNames('text-base font-medium', {
                  'text-red-500': !isValid,
                })}
              >
                {t(key)}:
              </div>
              <div>
                {isValid ? (
                  <CheckCircleOutlineOutlinedIcon
                    fontSize={'medium'}
                    color={'success'}
                  />
                ) : (
                  <HighlightOffOutlinedIcon
                    fontSize={'medium'}
                    color={'error'}
                  />
                )}
              </div>
            </div>
          ))
        ) : (
          <p>No validation data provided.</p>
        )}
      </div>
    </div>
  );
};

export default PriceHubbleReportRequirements;
