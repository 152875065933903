import { Box, Grid } from '@mui/material';
import GoBackTag from '../../../features/tags/goBackTag';
import PromotionDescription from './description';
import SinglePromotionHeader from './header';
import PromotionProperties from './properties';
import PromotionPlan from './plan';
import PromotionPresentation from './presentation';
import useSWR from 'swr';
import { IPromotionApi } from '../../../types/promotion';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import useLocale from '../../../hooks/useLocale';
import PromotionGallery from './gallery';
import PromotionContact from './promotionContact';
import SpinnerLoading from '../../../features/spinner/spinnerLoading';
import PromotionDocuments from './documents';
import PromotionFeatures from './features';

function SinglePromotion() {
  const { id } = useParams();
  const [callApi, setCallApi] = useState(false);
  const { data } = useSWR<IPromotionApi>(callApi && `/v1/promotions/${id}`);
  const { locale } = useLocale();
  useEffect(() => {
    setCallApi(true);
  }, [locale]);

  if (!data || !id) return <SpinnerLoading />;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
      <div>
        <GoBackTag link="/promotions" />

        <SinglePromotionHeader
          id={data.id}
          reference={data.reference}
          createdAt={data.createdAt}
          archive={data.archive}
        />
      </div>
      <Grid container spacing={1}>
        <Grid item xs={8} className="flex !flex-col gap-8">
          <PromotionDescription data={data} />
          <PromotionProperties data={data} />
          <PromotionGallery data={data} />
          <PromotionPlan data={data} />
          <PromotionDocuments data={data} />
        </Grid>
        <Grid item xs={4} className="flex !flex-col gap-8">
          <PromotionContact data={data} />
          <PromotionPresentation data={data} />
          <PromotionFeatures featuresStyle="!grid-cols-2" data={data} />
        </Grid>
      </Grid>
    </Box>
  );
}

export default SinglePromotion;
