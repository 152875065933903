import { ClickAwayListener, Modal } from "@mui/material";
import { Box, Fade } from "@mui/material";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import { ApiListing } from "../../../types/api";
import { ILeadItem } from "../../../types/contacts";
import useColumns from "../stepper/steps/leads/useColumns";
import TableGridX from "../../../components/tableGridX";
import { generateSkeletonItems } from "../stepper/steps/leads";
import CloseIcon from "@mui/icons-material/Close";

type Props = {
  id: number | null;
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isPromotion?: boolean
};

const itemsPerPage = 5;

const skeletonItems = generateSkeletonItems(itemsPerPage)

export default function ViewArchivedLeads({
                                            isModalOpen,
                                            setIsModalOpen,
                                            id,
                                            isPromotion
                                          }: Props) {
  const { t } = useTranslation("propertiesPage");
  const [page, setPage] = useState(1);
  const url = isPromotion ? 'promotions' : 'properties'
  const { data: leads } = useSWR<ApiListing<ILeadItem>>(
    `/v1/${url}/${id}/prospects?page=${page}&itemsPerPage=${itemsPerPage}`
  );

  const rows = useMemo(() => {
    if (leads && leads['hydra:member']) {
      return leads['hydra:member'].map((item: ILeadItem) => ({
        ...item,
        parentId: id,
      }));
    }
    return [];
  }, [leads]);

  const columns = useColumns({
    isLoading: !leads,
    isArchived: true,
  });

  const totalCount = leads?.['hydra:totalItems'] || 0;
  const showedRows = leads ? rows : skeletonItems;

  const onClose = () => {
    setIsModalOpen(false);
  };

  return (
    <Modal
      open={isModalOpen}
      onClose={onClose}
      classes={{ root: "grid place-items-center" }}
    >
      <ClickAwayListener onClickAway={onClose}>
        <Fade in={isModalOpen}>
          <Box
            sx={{
              maxHeight: "90vh",
              overflowY: "auto",
              overflowX: "hidden",
              width: {
                xs: "70%"
              },
              borderRadius: 2,
              bgcolor: "background.paper",
              boxShadow: 24
            }}
          >
            <div className={"p-8"}>
              <div className={"flex justify-between items-center w-full mb-8"}>
                <p className={"text-2xl"}>
                  {t("propertiesPage:leads")}
                </p>
                <button className={'transition-opacity duration-300 opacity-80 hover:opacity-100'} onClick={onClose}>
                  <CloseIcon />
                </button>
              </div>
              <div className={"h-full w-full with-border"}>
                <TableGridX
                  key={String(!!rows)}
                  rows={showedRows}
                  // @ts-ignore
                  autoPageSize
                  autoHeight
                  columns={columns}
                  pageSize={itemsPerPage}
                  page={page - 1}
                  rowCount={totalCount}
                  paginationMode={'server'}
                  onPageChange={(page: number) => {
                    setPage(page + 1);
                  }}
                  rowHeight={100}
                  sx={{
                    '& .MuiDataGrid-virtualScroller': {
                      overflow: 'hidden',
                      minHeight: '100px !important',
                    },
                  }}
                />
              </div>
            </div>
          </Box>
        </Fade>
      </ClickAwayListener>
    </Modal>
  );
}
