import { FC, useEffect, useState } from 'react';

import GooglePlacesWithMap from './googlePlacesWithMap';
import useLocale from '../../../hooks/useLocale';
import { IAddress } from '../../../types/address';
import axios from 'axios';
import { useAuth } from "../../../hooks/useAuth";
import useSWR from 'swr';
import { FileNode } from '../../../types/hierarchy/legacy';

const AddressInput: FC<{
  value?: IAddress;
  onChange: (address: IAddress) => unknown;
  withoutMaps?: boolean;
  replaceClassNames?: string;
  withCountriesRestriction?: boolean;
}> = ({ value, onChange, withoutMaps = false, replaceClassNames, withCountriesRestriction }) => {
  const { locale } = useLocale();
  const separator = ', ';
  const defaultAddress = value?.translations?.[locale]?.adresse || '';
  const [defaultRoute, defaultStreetNumber] = defaultAddress.split(separator);
  const [restrictedCountries, setRestrictedCountries] = useState<string[] | undefined>();
  const {
    user: {  company, filial },
  } = useAuth();
  const { data: companyData } = useSWR<FileNode>(company ? `/companies/${company?.id}=${locale}` : null);

  const getCountriesRestriction = (): string[] | undefined => {
    if (companyData) {
      if(filial) {
        const code = companyData.children?.find((el) => el.id === filial.id)?.country
        if(code) {
          return [code]
        }
      } else {
        return companyData.children?.map((el) => el.country as string);
      }
    }
  }

  const fetchTranslation = async (cityName: string, languageKey: string): Promise<string> => {
    let cityNameTranslation = cityName;
    try {
      const response = await axios.get(`http://api.geonames.org/searchJSON?q=${cityName}&maxRows=1&username=${process.env.REACT_APP_GEONAMES_USERNAME}&lang=${languageKey}`);
      if (response.data.geonames.length > 0) {
        cityNameTranslation = response.data.geonames[0].name;
      }
    } catch (error) {
      console.error('Error fetching city translation:', error);
      return cityName;
    }

    return cityNameTranslation;
  };

  const onPlaceChange = async (
    placeResult: google.maps.places.PlaceResult,
    placesByLanguage: any
  ) => {
    const location = placeResult.geometry?.location;

    const address: IAddress = {
      latitude: null,
      longitude: null,
      zipCode: '',
      locale,
      translations: {},
    };
    if (location) {
      const { lat, lng } = location.toJSON();
      address.latitude = lat;
      address.longitude = lng;
    }

    // }
    const { address_components } = placeResult;
    if (address_components) {
      let route: string | undefined;
      let streetNumber: string | undefined;

      for (const languageKey of Object.keys(placesByLanguage)) {
        const components = placesByLanguage[languageKey];
        const translationObject = (address.translations[languageKey] = {
          locale: languageKey,
          country: '',
          countryCode: '',
          city: '',
          zone: '',
          adresse: '',
          canton: '',
          district: '',
        });
        for (const component of components) {
          const {
            long_name,
            short_name,
            types: [type],
          } = component;

          switch (type) {
            case 'postal_code':
              address.zipCode = long_name;
              break;
            case 'country':
              translationObject.country = short_name;
              break;
            case 'locality':
            case 'postal_town':
              translationObject.city = await fetchTranslation(long_name, languageKey);
              break;
            case 'sublocality_level_1':
              translationObject.zone = long_name;
              break;
            case 'route':
              route = long_name;
              break;
            case 'street_number':
              streetNumber = long_name;
              break;
            case 'administrative_area_level_1':
              translationObject.canton = long_name;
              break;
            case 'administrative_area_level_2':
              translationObject.district = long_name;
              break;
            default:
            // Do nothing.
          }
        }

        // Combine route and street number, if available
        const routeAndStreetNumber = [
          route || defaultRoute,
          streetNumber || defaultStreetNumber,
        ]
          .filter(Boolean)
          .join(separator);

        // Set the 'adresse' property in the translation object
        translationObject.adresse = routeAndStreetNumber;
      }
    }

    onChange(address);
  };

  useEffect(() => {
    if (withCountriesRestriction) {
      setRestrictedCountries(getCountriesRestriction());
    }
  }, [companyData]);

  const mapCenter = {
    lat: value?.latitude || null,
    lng: value?.longitude || null,
  };
  const hasCoordinates = mapCenter.lat && mapCenter.lng;
  const zoom = hasCoordinates ? 15 : 12;

  return (
    <GooglePlacesWithMap
      withoutMaps={withoutMaps}
      center={mapCenter}
      onPlaceChange={onPlaceChange}
      zoom={zoom}
      replaceClassNames={replaceClassNames}
      restrictedLocales={restrictedCountries}
    />
  );
};

export default AddressInput;
