import { Controller, UseFormReturn } from 'react-hook-form';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { IPropertyApi } from '../../../../../types/property';
import { IContact } from '../../../../../types/contacts';

import BooleanOption from '../../../../../components/popupStepper/controls/booleanOption';
import Multiselect from '../../../../../components/popupStepper/controls/multiselect';

import { http } from '../../../../../libs/axios';
import { getContactName } from '../../../../../helpers/utils/contacts';
import SpinnerLoading from '../../../../../features/spinner/spinnerLoading';

export interface SettingsFormState {
  individual_owner: string[];
  company_owner: string[];
  enable_owners: boolean;
  agent_in_charge: boolean;
  pricing: boolean;
  contact_requests: boolean;
  all_tasks: boolean;
  display_tasks_note: boolean;
}

const OwnersReportSettings = ({
  methods,
  propertyId,
}: {
  methods: UseFormReturn<SettingsFormState>;
  propertyId: string | number;
}) => {
  const { data: property } = useSWR<IPropertyApi>(
    `/v1/properties/${propertyId}`
  );
  const { control } = methods;
  const { anyOwners, individualOwners, companyOwners, isLoading } =
    useContactGroups(property);

  const { t } = useTranslation('propertiesPage');
  const booleanSettings = [
    {
      title: t('owner_information'),
      name: 'enable_owners',
      extra: isLoading ? (
        <SpinnerLoading />
      ) : (
        anyOwners && (
          <>
            {individualOwners?.length ? (
              <Controller
                name="individual_owner"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Multiselect
                    options={individualOwners}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
            ) : null}
            {companyOwners?.length ? (
              <Controller
                name="company_owner"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Multiselect
                    options={companyOwners}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
            ) : null}
          </>
        )
      ),
    },
    { title: t('agent_in_charge'), name: 'agent_in_charge' },
    { title: t('pricing_and_updates'), name: 'pricing' },
    {
      title: t('contact_requests_related'),
      name: 'contact_requests',
    },
    { title: t('tasks'), name: 'all_tasks' },
    { title: t('display_task_notes'), name: 'display_tasks_note' },
  ];

  useAutoResetOwners(methods);

  return (
    <>
      {booleanSettings.map((setting) => (
        <Controller
          key={setting.name}
          name={setting.name as keyof SettingsFormState}
          control={control}
          render={({ field: { value, onChange } }) => (
            <BooleanOption
              {...setting}
              value={value as boolean}
              onChange={onChange}
            />
          )}
        />
      ))}
    </>
  );
};

export default OwnersReportSettings;

function useAutoResetOwners({
  watch,
  setValue,
}: UseFormReturn<SettingsFormState>) {
  const enable_owners = watch('enable_owners');
  useEffect(() => {
    if (!enable_owners) {
      setValue('individual_owner', [], { shouldValidate: true });
      setValue('company_owner', [], { shouldValidate: true });
    }
  }, [enable_owners, setValue]);
}

function useContactGroups(property?: IPropertyApi) {
  const contactDetails = useContactsFullDetails(property);
  return useMemo(() => {
    const companyOwners = contactDetails
      ?.filter((contact) => contact.company)
      .map((contact) => {
        return {
          label: getContactName(contact),
          value: String(contact.company.id),
        };
      });
    const individualOwners = contactDetails
      ?.filter((contact) => contact.individual)
      .map((contact) => {
        return {
          label: getContactName(contact),
          value: String(contact.individual.id),
        };
      });
    const anyOwners = Boolean(
      individualOwners?.length || companyOwners?.length
    );

    return {
      companyOwners,
      individualOwners,
      anyOwners,
      isLoading: contactDetails === undefined,
    };
  }, [contactDetails]);
}

function useContactsFullDetails(property?: IPropertyApi) {
  const contacts = useMemo(
    () => property?.owners.map(({ contact }) => contact),
    [property]
  );
  const [details, setDetails] = useState<IContact[]>();
  useEffect(() => {
    if (contacts) {
      const promises = contacts.map((contact) =>
        http.get(`/contacts/${contact.id}`)
      );
      Promise.all(promises).then((responses) => {
        setDetails(responses.map((response) => response.data));
      });
    }
  }, [contacts]);
  return details;
}
