import { Box } from '@mui/material';
import DashboardIcon from '../../features/icons/dashboardIcon';
import AgencyIcon from '../../features/icons/agencyIcon';
import UserIcon from '../../features/icons/userIcon';
import PromoIcon from '../../features/icons/promoIcon';
import PropertiesIcon from '../../features/icons/propertiesIcon';
import { useTranslation } from 'react-i18next';
import LangSwitcher from '../footer/langSwitcher';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useAuth } from '../../hooks/useAuth';
import ContactIcon from '../../features/icons/contactIcon';
import NavLinkItem from './navLinkItem';
import i18next from 'i18next';
import SettingsBarsIcon from '../../features/icons/settingsBarsIcon';
import useLocale from '../../hooks/useLocale';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
type Props = {
  setIsOpen?: Dispatch<SetStateAction<boolean>>;
};

export type LinkT = {
  path?: string;
  name: string;
  icon?: any;
  items?: LinkT[];
  collapsed?: boolean;
};
const toggleSideMenuItem = (navLinks: LinkT[], currentName: string) => {
  const updatedNavLinksItems = navLinks.map((item) => {
    const { name, collapsed } = item;
    if (i18next.t(name) === currentName) {
      return {
        ...item,
        collapsed: !collapsed, // Toggle the collapsed property
      };
    }
    return {
      ...item,
      collapsed: false, // Toggle the collapsed property
    };
  });

  return updatedNavLinksItems;
};
export default function Nav({ setIsOpen }: Props) {
  const { t } = useTranslation('common');
  const [navLinks, setNavLinks] = useState<LinkT[]>([]);
  const [currentLink, setCurrentLink] = useState('');
  const { locale } = useLocale();
  const isAdmin = JSON.parse(localStorage.getItem('isAdmin') as string);
  useEffect(() => {
    setCurrentLink(window.location.pathname);
  }, [window.location.pathname]);
  const {
    user: { roles },
  } = useAuth();

  const navLinksItems = [
    {
      path: '/',
      name: t('Dashboard'),
      icon: (
        <DashboardIcon color={currentLink === '/' ? '#3446B9' : '#1D1D1F'} />
      ),
    },

    {
      path: '/agencies',
      name: t('Agencies'),
      icon: (
        <AgencyIcon
          color={currentLink === '/agencies' ? '#3446B9' : '#1D1D1F'}
        />
      ),
    },

    {
      path: '/users',
      name: t('Users'),
      icon: (
        <UserIcon color={currentLink === '/users' ? '#3446B9' : '#1D1D1F'} />
      ),
    },
    {
      name: t('Properties'),
      icon: (
        <PropertiesIcon
          color={
            currentLink === '/properties' ||
            currentLink === '/properties/archivedProperties'
              ? '#3446B9'
              : '#1D1D1F'
          }
        />
      ),
      items: [
        {
          path: '/properties',
          name: t('ActiveProperties'),
        },
        {
          path: '/properties/archivedProperties',
          name: t('Archivedproperties'),
        },
        {
          path: '/properties/networks',
          name: t('MLS_Networks'),
        },
      ],
      collapsed: false,
    },
    {
      name: t('Promotions'),
      icon: (
        <PromoIcon
          color={
            currentLink === '/promotions' ||
            currentLink === '/promotions/archived'
              ? '#3446B9'
              : '#1D1D1F'
          }
        />
      ),
      items: [
        {
          path: '/promotions',
          name: t('active_promotions'),
        },
        {
          path: '/promotions/archived',
          name: t('archived_promotions'),
        },
      ],
      collapsed: false,
    },
    {
      name: t('Contacts'),
      icon: (
        <ContactIcon
          color={currentLink.includes('/contacts') ? '#3446B9' : '#1D1D1F'}
        />
      ),
      items: [
        {
          path: '/contacts',
          name: t('active_contacts'),
        },
        {
          path: '/contacts/archived',
          name: t('archived_contacts'),
        },
        {
          path: '/contacts/rejected',
          name: t('rejected_contacts'),
        },
      ],
      collapsed: false,
    },
    {
      path: '/leads',
      name: t('Leads'),
      icon: (
        <MailOutlineIcon
          fontSize="small"
          sx={{
            width: '1rem',
            color: currentLink === '/leads' ? '#3446B9' : '#1D1D1F',
          }}
        />
      ),
    },

    {
      path: '/settings',
      name: t('Settings'),
      icon: (
        <SettingsBarsIcon
          color={
            currentLink.includes('/settings') ||
            currentLink === '/brochure-templates'
              ? '#3446B9'
              : '#1D1D1F'
          }
        />
      ),
    },
  ];

  const toggleCollapse = (currentName: string) => {
    const NavLinksItems = toggleSideMenuItem(navLinks, currentName);
    setNavLinks(NavLinksItems);
  };
  useEffect(() => {
    let newNavLink = [];
    if (roles.includes('ROLE_ASSISTANT')) {
      newNavLink = navLinksItems.filter(
        (item) =>
          !item.path?.includes('agencies') &&
          !item.path?.includes('users') &&
          !item.path?.includes('settings')
      );
    }
    //  else if (roles?.includes('ROLE_USER')) {
    //   newNavLink = navLinksItems.filter(
    //     (item) => !item.path?.includes('settings')
    //   );
    // }
    else {
      if (isAdmin.isAdmin) {
        if (roles.includes('ROLE_ADMIN_OPERATOR')) {
          newNavLink = navLinksItems.filter(
            (item) => !item.path?.includes('agencies')
          );
        } else {
          newNavLink = [...navLinksItems];
        }
      } else {
        newNavLink = navLinksItems.filter(
          (item) =>
            !item.path?.includes('agencies') && !item.path?.includes('settings')
        );
      }
    }

    setNavLinks(
      newNavLink.map((item) => {
        const { items } = item;
        if (items && items.find((item) => item.path === currentLink)) {
          return {
            ...item,
            collapsed: true, // Toggle the collapsed property
          };
        }
        return {
          ...item,
          collapsed: false, // Toggle the collapsed property
        };
      })
    );
  }, [currentLink, locale]);

  return (
    <>
      <Box component="ul" className="flex flex-col pt-10">
        {navLinks.map((navLinkItem, i) => (
          <NavLinkItem
            key={`${navLinkItem.name}${i}`}
            item={navLinkItem}
            toggleCollapse={toggleCollapse}
            collapsed={navLinkItem.collapsed}
            setIsOpen={setIsOpen}
          />
        ))}
      </Box>
      <Box className="mt-auto w-full mb-6 px-8 flex items-center">
        <LangSwitcher />
      </Box>
    </>
  );
}
