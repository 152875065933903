/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import { Controller, FormProvider, useController } from 'react-hook-form';
import useSWR, { mutate } from 'swr';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
  IconButton,
} from '@mui/material';
import Rating from '@mui/material/Rating';
import { stepperFormId } from '../../../../../const';
import {
  categoryOptionType,
  getOptionsByLanguageString,
  optionType,
  referenceAgency,
} from '../../../../../const/propertiesOptions';
import {
  IProperty,
  PropertyStepperStep,
  PublishedLocation,
} from '../../../../../types/property';
import { ICompany } from "../../../../../types/hierarchy/legacy";

import { selectOptionStringT } from '../../../../../components/form/selectFormField';
import StepsFieldset from '../../../../../components/stepper/stepsFieldset';
import InputFormField from '../../../../../components/form/inputFormField';
import SelectFormField from '../../../../../components/form/selectFormField';
import DateRangeField from '../../../../../components/form/dateRangeField';
import ControlledSelectedFormWithHierarchy from '../../../../../components/form/ControlledSelectedFormWithHierarchy';
import AutoCompleteField, {
  AutocompleteOption,
} from '../../../../../components/form/autoCompleteField';
import { findTreeNode } from '../../../../agencies/tree/utils';
import SpinnerLoading from '../../../../../features/spinner/spinnerLoading';
import AddressInputWithTextfields from '../../../../../components/form/addressInput/withTextfields';
import useLocale from '../../../../../hooks/useLocale';
import { useAuth } from '../../../../../hooks/useAuth';
import { useFiltersProperties } from '../../../../../hooks/useFiltersProperties';
import { HierarchyNode } from '../../../../../types/hierarchy';
import { useTranslation } from 'react-i18next';
import StarIcon from '../../../../../features/icons/starIcon';
import WarningIcon from '../../../../../features/icons/warningIcon';
import StatusSelect from './statusSelect';
import { useProtectedForm } from '../../../../../hooks/useProtectedForm';
import CustomAttributesDisplay from '../../customAttributesDisplay';
import SwitcherFormField from '../../../../../components/form/switcherFormField';
import HistoryIcon from '@mui/icons-material/History';
import HistoryReferencesPopup from './historyReferencesPopup';
import { toast } from 'react-toastify';
import { http } from '../../../../../libs/axios';
import InputLayout from '../../../../../components/form/components/inputLayout';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { numberInputProps } from '../details/const';

type PublishedLocationKeys = keyof PublishedLocation;
interface AgencyReference {
  id: number;
  value: string;
  label: string;
  active?: boolean;
}
const PropertyGeneralInformationStep: FC<PropertyStepperStep> = ({
  serverData,
  onSubmit,
  defaultValues,
  isLoading,
}) => {
  const { locale } = useLocale();
  const formMethods = useProtectedForm<IProperty>(
    {
      defaultValues,
    },
    serverData
  );
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    control,
    resetField,
  } = formMethods;
  const {
    isAssistant,
    user: { users },
  } = useAuth();
  const { t } = useTranslation();
  const [disabledRadio, setDisabledRadio] = useState(false);
  const [historyReferences, setHistoryReferences] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const [defaultRef, setDefaultRef] = useState<AgencyReference | null>(null);
  const [valueReferenceAgency, setValueReferenceAgency] = useState('');
  const { data: statusOptionsApi } = useSWR<optionType[]>('statuses');
  const { data: referenceAgencyList } = useSWR<referenceAgency>(
    serverData && `/v1/properties/${serverData.id}/agency_references`
  );

  useEffect(() => {
    if (Array.isArray(referenceAgencyList?.agencyReferences)) {
      const activeReference = referenceAgencyList?.agencyReferences.find(
        (elem) => elem.active === true
      );
      if (activeReference) {
        setDefaultRef({
          id: activeReference.id,
          value: activeReference.agencyReference,
          label: activeReference.agencyReference,
        });
      }
    }
  }, [referenceAgencyList]);

  const formattedAgencyReferences =
    referenceAgencyList?.agencyReferences?.map((elem) => ({
      id: elem.id,
      value: elem.agencyReference,
      label: elem.agencyReference,
    })) || [];

  const { data: categoriesOptionsApi } =
    useSWR<categoryOptionType[]>('categories');
  const { data: availabilitiesOptionsApi } =
    useSWR<optionType[]>('availabilities');
  const statusOptions = getOptionsByLanguageString(statusOptionsApi, locale);

  const categoriesOptions = getOptionsByLanguageString(
    categoriesOptionsApi,
    locale
  );
  const availabilityOptions = getOptionsByLanguageString(
    availabilitiesOptionsApi,
    locale
  );
  // begin departments and brokers list
  const { assistantAgencyHierarchy, setAssistantAgencyHierarchy } =
    useFiltersProperties(); // address
  const { data: basicHierarchy } = useSWR<ICompany>(
    !isAssistant && '/users/hierarchy'
  );

  const { field } = useController({ name: 'department', control });
  let brokerDept: HierarchyNode | undefined;

  const hierarchy: HierarchyNode = isAssistant
    ? assistantAgencyHierarchy
    : basicHierarchy;
  if (hierarchy) {
    if (hierarchy.categoryEntity === 'Department') {
      brokerDept = hierarchy;
    } else {
      brokerDept = findTreeNode(hierarchy, watch('department'), 'Department');
    }
  }

  const brokersList = isAssistant
    ? users
      ? users.map(({ id, firstname, lastname }: any) => {
          return {
            id: String(id),
            label: `${firstname} ${lastname}`,
            value: `${firstname} ${lastname}`,
          };
        })
      : []
    : getBrokersList(brokerDept);
  const promotionList = getPromotionList(brokerDept, locale);

  // end departments and brokers

  // Get TypesList via category choice
  const [typeOptions, setTypeOptions] = useState<selectOptionStringT[]>([]);
  const currentCategoryValue = watch('category');

  useEffect(() => {
    resetField('type');
    if (currentCategoryValue?.length && categoriesOptionsApi) {
      const categoryId = Number(currentCategoryValue);

      const categorySelected = categoriesOptionsApi.filter(
        (type) => type.id === categoryId
      );

      const selectedTypeOptions = categorySelected[0]?.propertyTypes;
      const selectedTypeOptionsByLanguage = getOptionsByLanguageString(
        selectedTypeOptions,
        locale
      );

      setTypeOptions(selectedTypeOptionsByLanguage);
    }
  }, [categoriesOptionsApi, currentCategoryValue]);

  useEffect(() => {
    if (isAssistant && watch('mainBroker')) {
      setAssistantAgencyHierarchy(watch('mainBroker'));
    }
  }, [watch('mainBroker')]);

  const radioPublication = (
    firstRadio: PublishedLocationKeys,
    secondRadio: PublishedLocationKeys,
    e: any
  ) => {
    if (e.target.value == firstRadio) {
      setValue(`publishedLocation.${firstRadio}`, true);

      setValue(`publishedLocation.${secondRadio}`, false);
    } else {
      setValue(`publishedLocation.${firstRadio}`, false);

      setValue(`publishedLocation.${secondRadio}`, true);
    }
  };

  useEffect(() => {
    if (serverData == undefined) {
      setValue('publishedLocation.realBrochure', true);
      setValue('publishedLocation.realPortals', true);
      setValue('publishedLocation.realWebsite', true);
      setValue('replacementAddress', null);
    }
  }, []);

  const handleReference = async (update: string) => {
    const checkExistReference = formattedAgencyReferences.find(
      (elem) => elem.value == valueReferenceAgency
    );

    if (serverData) {
      setIsLoading(true);
      try {
        update == 'updateRef'
          ? checkExistReference
            ? await http.patch(
                `/v1/properties/${serverData.id}/agency_reference/${checkExistReference?.id}/activation`,
                { active: true }
              )
            : await http.patch(
                `/v1/properties/${serverData.id}/agency_reference/update`,
                { agencyReference: valueReferenceAgency }
              )
          : await http.patch(
              `/v1/properties/${serverData.id}/agency_reference/${defaultRef?.id}/activation`,
              { active: false }
            );
        mutate(
          serverData && `/v1/properties/${serverData.id}/agency_references`
        );
        mutate(serverData && `/v1/properties/${serverData.id}`);
        toast.success(
          t(
            'propertiesPage:agency_reference_has_been_successfully_saved'
          ) as string,
          {
            position: 'bottom-center',
            autoClose: 3000,
            style: { width: '500px' },
          }
        );
      } catch (error: any) {
        if (error.response && error.response.status === 481) {
          toast.error(
            t('propertiesPage:Reference_agency_already_exists') as string,
            {
              position: 'bottom-center',
              autoClose: 3000,
              style: { width: '500px' },
            }
          );
        } else {
          toast.error(
            t(
              'propertiesPage:An_unexpected_error_occurred_Please_try_again'
            ) as string,
            {
              position: 'bottom-center',
              autoClose: 3000,
              style: { width: '500px' },
            }
          );
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if (valueReferenceAgency == '') {
        handleReference('updateStatus');
      } else {
        handleReference('updateRef');
      }
    }
  };
  if (!isAssistant && !hierarchy) return <SpinnerLoading />;

  return (
    <>
      <FormProvider {...formMethods}>
        <form
          id={stepperFormId}
          onSubmit={handleSubmit(onSubmit)}
          className="grid gap-16"
        >
          <StepsFieldset title={t('common:general-information')}>
            <div key={locale}>
              <input
                type="hidden"
                {...register(`warningField.translations.${locale}.locale`)}
                value={locale}
              />
            </div>
            <div></div>
            <div>
              {serverData ? (
                <div className="flex items-end gap-2">
                  <InputLayout label={t('common:Reference_by_agency')}>
                    <Autocomplete
                      freeSolo
                      clearText={`${t('propertiesPage:delete_reference')}`}
                      className="Promotion_property w-full"
                      disablePortal
                      options={formattedAgencyReferences}
                      value={defaultRef ? defaultRef : null}
                      clearIcon={
                        <IconButton
                          onClick={() => setValueReferenceAgency('')}
                          sx={{
                            color: 'red',
                            width: '30px',
                            height: '30px',
                            '&:hover': {
                              color: 'white',
                              backgroundColor: 'transparent',
                            },
                          }}
                        >
                          <ClearIcon />
                        </IconButton>
                      }
                      renderOption={(props, option) => (
                        <li
                          {...props}
                          style={{
                            pointerEvents: option.label ? 'none' : 'auto',
                            color: option.label
                              ? 'rgba(0, 0, 0, 0.38)'
                              : 'inherit',
                          }}
                        >
                          {option?.label}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          variant="outlined"
                          {...params}
                          onChange={(e) => {
                            setValueReferenceAgency(e.target.value);
                          }}
                          onKeyPress={handleKeyPress}
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              <Box>
                                {' '}
                                {loading && (
                                  <div className="z-10 ml-2">
                                    {' '}
                                    <CircularProgress size={20} disableShrink />
                                  </div>
                                )}
                              </Box>
                            ),
                            endAdornment: (
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'space-around',
                                  alignItems: 'center',
                                  gap: 1,
                                }}
                              >
                                <Box>
                                  <Tooltip
                                    title={`${t('propertiesPage:Save')}`}
                                    arrow
                                    placement="top"
                                  >
                                    <Button
                                      sx={{
                                        '&.MuiButtonBase-root:hover': {
                                          bgcolor: 'transparent',
                                        },
                                        position: 'absolute',
                                        right: '5%',
                                        top: '12%',
                                      }}
                                      onClick={() => {
                                        if (valueReferenceAgency == '') {
                                          handleReference('updateStatus');
                                        } else {
                                          handleReference('updateRef');
                                        }
                                      }}
                                    >
                                      <CheckIcon sx={{ color: '#4CAF50' }} />
                                    </Button>
                                  </Tooltip>
                                </Box>

                                <Box>{params.InputProps.endAdornment}</Box>
                              </Box>
                            ),
                          }}
                          sx={{
                            '& .MuiAutocomplete-popupIndicator:hover': {
                              backgroundColor: 'transparent',
                            },
                            '& .MuiAutocomplete-clearIndicator': {
                              visibility: 'visible',
                              opacity: 1,
                              color: 'red',
                              padding: 0,
                            },
                            '& .MuiAutocomplete-clearIndicator:hover': {
                              backgroundColor: 'red',
                              color: 'white',
                            },
                            '& .MuiAutocomplete-endAdornment': {
                              marginRight: '-3px',
                            },
                          }}
                        />
                      )}
                    />
                  </InputLayout>
                  <Tooltip
                    title={t('propertiesPage:reference_history')}
                    arrow
                    placement="top"
                  >
                    <Button onClick={() => setHistoryReferences(true)}>
                      <HistoryIcon fontSize="large" />
                    </Button>
                  </Tooltip>
                </div>
              ) : (
                <InputFormField
                  label={t('common:Reference_by_agency')}
                  type="text"
                  {...register('agencyReference')}
                  value={watch('agencyReference')}
                  // disabled={!!defaultValues?.referenceAgency}
                />
              )}
            </div>
            <div className={'grid items-center grid-cols-3 gap-8 py-8'}>
              <div>
                <p className={'text-[12px] opacity-70'}>
                  {t('propertiesPage:Rating')}
                </p>
              </div>
              <div>
                <Controller
                  name="rating"
                  control={control}
                  render={({ field }) => (
                    <Rating
                      sx={{
                        '& .MuiRating-iconFilled': {
                          color: '#3446B9',
                        },
                      }}
                      icon={<StarIcon color={'#3446B9'} />}
                      emptyIcon={<StarIcon color={'#F5F5F7'} />}
                      defaultValue={field.value || undefined}
                      size="large"
                      max={3}
                      {...field}
                      onChange={(e, value: any) => {
                        setValue('rating', parseInt(value));
                      }}
                    />
                  )}
                />
              </div>
            </div>
            <StatusSelect
              statusOptions={statusOptions}
              getValues={getValues}
              setValue={setValue}
              id={serverData?.id}
              serverData={serverData}
              onSubmit={handleSubmit(onSubmit)}
              isLoading={isLoading}
            />
            <ControlledSelectedFormWithHierarchy
              className="Department_property"
              label={t('common:Department')}
              node={hierarchy}
              isRequired
              {...field}
              isDisabled={isAssistant && !watch('mainBroker')}
            />

            <SelectFormField
              className="Category_property"
              isRequired
              label={t('common:Category')}
              options={categoriesOptions}
              defaultValue={getValues('category')}
              {...register('category')}
            />
            <div className="grid gap-4">
              <SelectFormField
                className="Availability_property"
                // isRequired
                label={t('common:Availability')}
                options={availabilityOptions}
                defaultValue={getValues('availability')}
                {...register('availability')}
              />

              {watch('availability') === '4' && (
                <div>
                  <DateRangeField
                    nameFrom="availableFrom"
                    nameTo="availableTo"
                    labelFrom="Start date"
                    labelTo="End date"
                    control={control}
                  />
                </div>
              )}
            </div>
            <SelectFormField
              className="Property_type_property"
              isRequired
              disabled={!watch('category')?.length}
              label={t('common:Property_type')}
              options={typeOptions}
              defaultValue={getValues('type')}
              {...register('type')}
            />

            <AutoCompleteField
              className="Promotion_property w-1/2"
              options={promotionList}
              label={t('common:Promotion')}
              control={control}
              disabled={!watch('department')?.length}
              name="promotion"
            />

            <div className="flex gap-4 items-center">
              <InputFormField
                {...register(`warningField.translations.${locale}.value`)}
                label={t('common:Warning')}
                variant="outlined"
              />
              <span className="pt-3">
                <WarningIcon color="#FFA500" />
              </span>
            </div>
            <div className="flex justify-between items-center gap-32">
              <SwitcherFormField
                checked={serverData?.newBuilding}
                register={register}
                className="flex justify-between items-center w-1/2"
                name="newBuilding"
                label={t('common:New_building')}
              />
              <SwitcherFormField
                checked={serverData?.offMarket}
                register={register}
                className="flex justify-between items-center w-1/2"
                name="offMarket"
                label={'Off Market'}
              />
            </div>
          </StepsFieldset>

          <StepsFieldset title={t('common:Brokers_and_comission')}>
            <AutoCompleteField
              className="Broker_responsible_property"
              options={brokersList}
              label={t('common:Broker_responsible')}
              control={control}
              name="mainBroker"
              isRequired
            />
            <div>
              <InputFormField
                className="Commission_For_The_Broker_In_Charge_property"
                type="number"
                label={t('common:Commission_For_The_Broker_In_Charge')}
                inputProps={numberInputProps}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">%</InputAdornment>
                  ),
                }}
                // isRequired
                {...register(`rateMainBroker`, {
                  valueAsNumber: true,
                })}
              />

              {watch('rateMainBroker') ? (
                <Box className="flex items-center gap-4">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={Boolean(watch('mainBrokerHT'))}
                        {...register('mainBrokerHT', {
                          onChange: () => setValue('mainBrokerTTC', false),
                        })}
                        color="primary"
                      />
                    }
                    label="HT"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={Boolean(watch('mainBrokerTTC'))}
                        {...register('mainBrokerTTC', {
                          onChange: () => setValue('mainBrokerHT', false),
                        })}
                        color="primary"
                      />
                    }
                    label="TTC"
                  />
                </Box>
              ) : null}
            </div>

            <AutoCompleteField
              className="Intermediate_broker_property"
              options={brokersList}
              label={t('common:Intermediate_broker')}
              control={control}
              name="intermediateBroker"
            />
            <div>
              <InputFormField
                className="Commission_For_The_Intermediate_broker_property"
                type="number"
                label={t('common:Commission_For_The_Intermediate_broker')}
                inputProps={numberInputProps}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">%</InputAdornment>
                  ),
                }}
                {...register(`rateIntermediateBroker`, {
                  valueAsNumber: true,
                })}
              />{' '}
              {watch('rateIntermediateBroker') ? (
                <Box className="flex items-center gap-4">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={Boolean(watch('intermediateBrokerHT'))}
                        {...register('intermediateBrokerHT', {
                          onChange: () =>
                            setValue('intermediateBrokerTTC', false),
                        })}
                        color="primary"
                      />
                    }
                    label="HT"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={Boolean(watch('intermediateBrokerTTC'))}
                        {...register('intermediateBrokerTTC', {
                          onChange: () =>
                            setValue('intermediateBrokerHT', false),
                        })}
                        color="primary"
                      />
                    }
                    label="TTC"
                  />
                </Box>
              ) : null}
            </div>
          </StepsFieldset>

          <StepsFieldset title={t('common:Cadastral_data')}>
            <div className="col-span-2">
              <AddressInputWithTextfields
                name="address"
                isRequired
                withCountriesRestriction
              />
            </div>
          </StepsFieldset>

          <StepsFieldset title={t('common:Replacement_address')}>
            <div className="col-span-2">
              <AddressInputWithTextfields
                name="replacementAddress"
                setDisabledRadio={setDisabledRadio}
                disabledRadio={disabledRadio}
                withCountriesRestriction
                withEditableFields
              />
            </div>
          </StepsFieldset>
          <StepsFieldset
            className="grid grid-cols-1"
            title={t('common:Publication')}
          >
            <Typography className="w-full">
              {t(
                'common:Please_select_which_location_you_would_like_to_publish_per_channel.'
              )}
            </Typography>
            <div className="grid grid-cols-1 gap-12">
              <Box className="flex items-center gap-4">
                <Typography className="!text-base !min-w-[100px]">
                  {t('common:Website')} :
                </Typography>
                <RadioGroup
                  sx={{
                    flexDirection: 'row',
                    gap: '40px',
                  }}
                  defaultValue={
                    defaultValues?.publishedLocation?.alternativeWebsite
                      ? 'alternativeWebsite'
                      : 'realWebsite'
                  }
                  onChange={(e) => {
                    radioPublication('realWebsite', 'alternativeWebsite', e);
                  }}
                >
                  <FormControlLabel
                    value="realWebsite"
                    control={
                      <Radio className="Real_location_Website_property" />
                    }
                    label={t('common:Real_location')}
                  />
                  <Tooltip
                    componentsProps={{
                      tooltip: {
                        sx: {
                          backgroundColor: '#E7931C',
                          fontSize: '14px',
                          '& .MuiTooltip-arrow': {
                            color: '#E7931C',
                          },
                        },
                      },
                    }}
                    title={t(
                      'propertiesPage:Please_fill_the_fields_for_replacement_address'
                    )}
                    arrow
                    placement="top"
                    disableHoverListener={!disabledRadio}
                  >
                    <FormControlLabel
                      value="alternativeWebsite"
                      disabled={disabledRadio}
                      control={
                        <Radio className="Alternate_location_Website_property" />
                      }
                      label={t('common:Alternate_location')}
                    />
                  </Tooltip>
                </RadioGroup>
              </Box>
              <Box className="flex items-center gap-4">
                <Typography className="!text-base !min-w-[100px]">
                  {t('common:Brochure')} :
                </Typography>
                <RadioGroup
                  sx={{
                    flexDirection: 'row',
                    gap: '40px',
                  }}
                  defaultValue={
                    defaultValues?.publishedLocation?.alternativeBrochure
                      ? 'alternativeBrochure'
                      : 'realBrochure'
                  }
                  onChange={(e) => {
                    radioPublication('realBrochure', 'alternativeBrochure', e);
                  }}
                >
                  <FormControlLabel
                    value="realBrochure"
                    control={
                      <Radio className="Real_location_Brochure_property" />
                    }
                    label={t('common:Real_location')}
                  />
                  <Tooltip
                    componentsProps={{
                      tooltip: {
                        sx: {
                          backgroundColor: '#E7931C',
                          fontSize: '14px',
                          '& .MuiTooltip-arrow': {
                            color: '#E7931C',
                          },
                        },
                      },
                    }}
                    title={t(
                      'propertiesPage:Please_fill_the_fields_for_replacement_address'
                    )}
                    arrow
                    placement="top"
                    disableHoverListener={!disabledRadio}
                  >
                    <FormControlLabel
                      value="alternativeBrochure"
                      disabled={disabledRadio}
                      control={
                        <Radio className="Alternate_location_Brochure_property" />
                      }
                      label={t('common:Alternate_location')}
                    />
                  </Tooltip>
                </RadioGroup>
              </Box>
              <Box className="flex items-center gap-4">
                <Typography className="!text-base !min-w-[100px]">
                  {t('common:portals')} :
                </Typography>
                <RadioGroup
                  sx={{
                    flexDirection: 'row',
                    gap: '40px',
                  }}
                  defaultValue={
                    defaultValues?.publishedLocation?.alternativePortals
                      ? 'alternativePortals'
                      : 'realPortals'
                  }
                  onChange={(e) => {
                    radioPublication('realPortals', 'alternativePortals', e);
                  }}
                >
                  <FormControlLabel
                    value="realPortals"
                    control={
                      <Radio className="Real_location_Portals_property" />
                    }
                    label={t('common:Real_location')}
                  />
                  <Tooltip
                    componentsProps={{
                      tooltip: {
                        sx: {
                          backgroundColor: '#E7931C',
                          fontSize: '14px',
                          '& .MuiTooltip-arrow': {
                            color: '#E7931C',
                          },
                        },
                      },
                    }}
                    title={t(
                      'propertiesPage:Please_fill_the_fields_for_replacement_address'
                    )}
                    arrow
                    placement="top"
                    disableHoverListener={!disabledRadio}
                  >
                    <FormControlLabel
                      value="alternativePortals"
                      disabled={disabledRadio}
                      control={
                        <Radio className="Alternate_location_Portals_property" />
                      }
                      label={t('common:Alternate_location')}
                    />
                  </Tooltip>
                </RadioGroup>
              </Box>
            </div>
          </StepsFieldset>

          {defaultValues?.dataFields?.['general_information'] && (
            <CustomAttributesDisplay
              groupedDataFields={
                defaultValues?.dataFields?.['general_information']
              }
              fieldValues={defaultValues.fieldValues}
              register={register}
              watch={watch}
            />
          )}
        </form>
      </FormProvider>
      <HistoryReferencesPopup
        title={'References_History'}
        openModal={historyReferences}
        onClose={() => setHistoryReferences(false)}
        id={serverData?.id}
      />
    </>
  );
};

export default PropertyGeneralInformationStep;

export function getBrokersList(
  brokerDept: HierarchyNode | undefined
): AutocompleteOption[] {
  const brokersList: AutocompleteOption[] = [];
  if (brokerDept) {
    const { users } = brokerDept;
    if (users) {
      for (const user of users) {
        brokersList.push({
          id: String(user.id),
          label: `${user.firstname} ${user.lastname}`,
          value: `${user.firstname} ${user.lastname}`,
        });
      }
    }
  }
  return brokersList;
}
export function getPromotionList(
  brokerDept: HierarchyNode | undefined,
  locale: string
): AutocompleteOption[] {
  const promotionsList: AutocompleteOption[] = [];
  if (brokerDept) {
    const { promotions } = brokerDept;
    if (promotions) {
      for (const promotion of promotions) {
        const translation =
          promotion.translations[locale]?.name ||
          (locale === 'fr'
            ? promotion.translations['en']?.name
            : promotion.translations['fr']?.name) ||
          'No name available';

        promotionsList.push({
          id: `${promotion.id}`,
          label: translation,
          value: translation,
        });
      }
    }
  }
  return promotionsList;
}
