import { ReactElement, useState } from 'react';
import TableIcon from '../../features/icons/tableIcon';
import GridIcon from '../../features/icons/gridIcon';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box, Button } from '@mui/material';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import SpinnerLoading from '../../features/spinner/spinnerLoading';

interface Props {
  leftElement?: ReactElement;
  tableElement: ReactElement;
  gridElement: ReactElement;
  isGridView?: boolean;
  loading?: boolean;
  exportButton?: boolean;
  handleExport?: any;
}

const GridTableView = (props: Props) => {
  const isMobile = useMediaQuery('(min-width:1024px)');
  const {
    tableElement,
    gridElement,
    leftElement,
    exportButton,
    handleExport,
    isGridView,
    loading,
  } = props;

  const [gridView, toggleGridView] = useState(
    isGridView != undefined && isMobile ? false : true
  );

  const { t } = useTranslation('common');
  return (
    <div className={'flex flex-col h-auto flex-grow pb-8'}>
      <div
        className={classNames('w-full inline-flex mb-3 md:mb-0 lg:pb-6', {
          'md:justify-between justify-center items-start':
            leftElement || exportButton,
          'justify-end items-start': !leftElement,
        })}
      >
        {leftElement}
        {exportButton && (
          <Button
            onClick={() => handleExport()}
            disabled={loading}
            disableElevation
            sx={{
              px: 1.5,
              py: 1,
              borderRadius: 1,
              border: '1px solid #1E40AF !important',
            }}
          >
            {loading ? (
              <SpinnerLoading />
            ) : (
              <Box className="flex justify-center items-center" gap="10px">
                <div>
                  <SaveAltIcon fontSize="small" />
                </div>
                <div className="text-sm font-medium leading-none">
                  {t('propertiesPage:Export')}
                </div>
              </Box>
            )}
          </Button>
        )}
        <div className={'flex justify-end items-start gap-2'}>
          <button
            onClick={() => toggleGridView(false)}
            className={classNames(
              'hover:border-blue-800 hover:bg-[#F5F5F7] hidden duration-300 transition-colors px-4 py-3 bg-white rounded border justify-center items-center gap-2 lg:flex',
              {
                'border-blue-800 text-blue-800 pointer-events-none': !gridView,
                'border-gray-200 text-neutral-500': gridView,
              }
            )}
          >
            <div className="w-4 h-4 justify-center items-center flex">
              <TableIcon width={'16'} height={'16'} color={'currentColor'} />
            </div>
            <div className="text-sm font-medium leading-none">{t('Table')}</div>
          </button>
          <button
            onClick={() => toggleGridView(true)}
            className={classNames(
              'hover:border-blue-800 hover:bg-[#F5F5F7] hidden duration-300 transition-colors px-4 py-3 bg-white rounded border justify-center items-center gap-2 lg:flex',
              {
                'border-blue-800 text-blue-800 pointer-events-none': gridView,
                'border-gray-200 text-neutral-500': !gridView,
              }
            )}
          >
            <GridIcon width={'14'} height={'14'} color={'currentColor'} />
            <div className="text-sm font-medium leading-none">{t('Grid')}</div>
          </button>
        </div>
      </div>
      {gridView ? gridElement : tableElement}
    </div>
  );
};

export default GridTableView;
