import React, { useState } from 'react';
import WidgetWrapper from './wrapper';
import { WidgetProps } from '../index';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Tooltip } from '@mui/material';
import { IPropertyApi } from '../../../types/property';
import PublishedPropertiesPopup from '../components/publishedPropertiesPopup';
import { useIsRent } from '../../../hooks/useIsRent';
import { formatPriceWithCurrency } from '../utils/useOffersListColumns';
import PriceIcon from '../../../features/icons/priceIcon';
import { getInitials } from './offers';

const Item = (props: { item: IPropertyApi }) => {
  const [open, setOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width:900px)');
  const handleTouchStart = () => {
    if (isMobile) {
      setTimeout(() => setOpen(true), 10);
    }
  };

  const handleTouchEnd = () => {
    if (isMobile) {
      setTimeout(() => setOpen(false), 100);
    }
  };
  const { item } = props;
  const isRent = useIsRent(item);
  return (
    <div className="self-stretch pb-2 border-b border-gray-200 grid grid-cols-3 gap-4">
      <Tooltip
        title={item.agencyReference ? item.agencyReference : item.reference}
        arrow
        placement="top"
      >
        <Link
          className={
            'text-blue-800 text-xs sm:text-sm font-medium leading-none'
          }
          to={`/properties/${item.id}`}
        >
          <span className="block whitespace-nowrap lg:truncate">
            {item.agencyReference ? item.agencyReference : item.reference}
          </span>
        </Link>
      </Tooltip>

      {isMobile ? (
        <Tooltip
          title={item?.department?.name}
          arrow
          placement="top"
          onClick={handleTouchStart}
          open={open}
          onOpen={handleTouchStart}
          onClose={handleTouchEnd}
        >
          <div className="grow shrink basis-0 justify-start items-center gap-2 flex overflow-hidden">
            <div className="h-6 p-1 bg-neutral-100 rounded-[100px] justify-center items-center gap-2 flex">
              <div className="text-zinc-900 text-xs font-normal font-['Inter'] leading-none">
                {getInitials(item?.department?.name)}
              </div>
            </div>

            <div className="grow shrink basis-0 text-zinc-900 text-xs sm:text-sm font-medium font-['Inter'] pb-0.5 leading-1">
              {item?.department?.name}
            </div>
          </div>
        </Tooltip>
      ) : (
        <Tooltip title={item?.department?.name} arrow placement="top">
          <div className="grow shrink basis-0 justify-start items-center gap-2 flex overflow-hidden">
            <div className="h-6 p-1 bg-neutral-100 rounded-[100px] justify-center items-center gap-2 flex">
              <div className="text-zinc-900 text-xs font-normal font-['Inter'] leading-none">
                {getInitials(item?.department?.name)}
              </div>
            </div>

            <div className="grow shrink basis-0 text-zinc-900 text-xs sm:text-sm font-medium font-['Inter'] pb-0.5 leading-1 lg:truncate">
              {item?.department?.name}
            </div>
          </div>
        </Tooltip>
      )}
      <div className="grow shrink justify-start items-center gap-2 flex overflow-hidden">
        {isMobile ? (
          <Tooltip
            title={
              isRent
                ? formatPriceWithCurrency(
                    item.pricingRent?.displayedPrice,
                    item?.pricingRent?.currency?.value
                  )
                : formatPriceWithCurrency(
                    item.pricingBuy?.displayedPrice,
                    item?.pricingBuy?.currency?.value
                  )
            }
            arrow
            placement="top"
            onClick={handleTouchStart}
            open={open}
            onOpen={handleTouchStart}
            onClose={handleTouchEnd}
          >
            <div className="grow shrink basis-0 h-4 justify-start items-start gap-2 flex">
              <div className="w-4 h-4 relative opacity-30">
                <PriceIcon />
              </div>
              <div className="grow shrink basis-0 text-zinc-900 text-xs sm:text-sm font-medium font-['Inter'] pb-0.5 whitespace-nowrap">
                {isRent
                  ? formatPriceWithCurrency(
                      item.pricingRent?.displayedPrice,
                      item?.pricingRent?.currency?.value
                    )
                  : formatPriceWithCurrency(
                      item.pricingBuy?.displayedPrice,
                      item?.pricingBuy?.currency?.value
                    )}
              </div>
            </div>
          </Tooltip>
        ) : (
          <Tooltip
            title={
              isRent
                ? formatPriceWithCurrency(
                    item.pricingRent?.displayedPrice,
                    item?.pricingRent?.currency?.value
                  )
                : formatPriceWithCurrency(
                    item.pricingBuy?.displayedPrice,
                    item?.pricingBuy?.currency?.value
                  )
            }
            arrow
            placement="top"
          >
            <div className="grow shrink basis-0 h-4 justify-start items-start gap-2 flex lg:truncate">
              <div className="w-4 h-4 relative opacity-30">
                <PriceIcon />
              </div>
              <div className="grow shrink basis-0 text-zinc-900 text-xs sm:text-sm font-medium font-['Inter'] pb-0.5 leading-1 lg:truncate">
                {isRent
                  ? formatPriceWithCurrency(
                      item.pricingRent?.displayedPrice,
                      item?.pricingRent?.currency?.value
                    )
                  : formatPriceWithCurrency(
                      item.pricingBuy?.displayedPrice,
                      item?.pricingBuy?.currency?.value
                    )}
              </div>
            </div>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

const PublishedPropertiesWidget: React.FC<WidgetProps> = (props) => {
  const { data } = props;
  const { t } = useTranslation('propertiesPage');
  const [openModal, setOpenModal] = useState(false);
  const isLowerThen520 = useMediaQuery('(max-width:520px)');
  return (
    <WidgetWrapper
      emptyData={!data['published-properties']?.length}
      title={'published_properties'}
      linkText={'ViewAll'}
      link={null}
      onButtonClick={() => setOpenModal(true)}
    >
      <div
        className={`flex-col justify-center items-start gap-2 inline-flex ${
          isLowerThen520 ? 'w-[440px]' : 'w-full'
        }`}
      >
        <div className=" pb-2 w-full grid grid-cols-3 gap-4">
          <div className="text-zinc-900 text-sm font-medium font-['Inter'] leading-1">
            {t('Reference')}
          </div>
          <div className="text-zinc-900 text-sm font-medium font-['Inter'] leading-1">
            {t('Related_agency')}
          </div>
          <div className="text-zinc-900 text-sm font-medium font-['Inter'] ml-2 leading-1">
            {t('price')}
          </div>
        </div>
        {data['published-properties']?.map((item, index) => (
          <Item key={index} item={item} />
        ))}
      </div>

      <PublishedPropertiesPopup
        title={t('published_properties')}
        openModal={openModal}
        onClose={() => setOpenModal(false)}
      />
    </WidgetWrapper>
  );
};

export default PublishedPropertiesWidget;
