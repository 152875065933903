import { useEffect, useState } from 'react';
import NotificationsList from '../../notificationsList';
import useSWR from 'swr';
import { IPersonalNotification } from '../../../../types/notification';
import { Typography } from '@mui/material';
import { ApiListing } from '../../../../types/api';
import NotificationFilter from '../notificationFilter';
import { useTotalNotification } from '../../../../app/providers/notification';
import { useTranslation } from 'react-i18next';
import { http } from '../../../../libs/axios';

const PersonalNotifications = () => {
  const { setNewTotalNotifications } = useTotalNotification();
  const { t } = useTranslation('notificationsPage');
  const [page, setPage] = useState<number>(1);
  const [count, setCount] = useState<number>(0);
  const [apiParams, setApiParams] = useState<string>('isDeleted=false');
  const [isLoading, setIsLoading] = useState(false);
  const [dataList, setDataList] = useState<IPersonalNotification[]>([]);
  const [activeIndex, setActiveIndex] = useState<number>(1);
  const APIkey = `/personal_notifications?${apiParams}&page=${page}&itemsPerPage=${10}`;
  const { data: notificationsList } =
    useSWR<ApiListing<IPersonalNotification>>(APIkey);

  useEffect(() => {
    if (notificationsList) {
      setDataList(notificationsList['hydra:member']);
    }
  }, [isLoading]);

  const fetchData = async () => {
    try {
      const res = await http.get(
        `/personal_notifications?isRead=false&page=${page}&itemsPerPage=${10}`
      );
      setCount(res.data['hydra:totalItems']);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if (notificationsList) {
      const UnreadedList = notificationsList['hydra:member'].filter(
        (elm: IPersonalNotification) => elm.isRead === false
      );
      setNewTotalNotifications(count);
      const ReadedList = notificationsList['hydra:member'].filter(
        (elm: IPersonalNotification) => elm.isRead === true
      );
      switch (activeIndex) {
        case 2:
          setApiParams('isRead=false');
          setDataList(UnreadedList);
          break;
        case 3:
          setApiParams('isRead=true');
          setDataList(ReadedList);
          break;

        default:
          setApiParams('isDeleted=false');
          setDataList(notificationsList['hydra:member']);
          break;
      }
    }
    fetchData();
  }, [activeIndex, notificationsList]);

  if (!notificationsList || !notificationsList['hydra:member'].length)
    return null;
  return (
    <div>
      <Typography
        className="sm:!text-2xl"
        sx={{
          lineHeight: '28px',
          borderBottom: '1px solid #E6E8EC',
          padding: '8px 0px',
          marginBottom: '32px',
        }}
      >
        {t('All_notifications')}
      </Typography>
      <div className="mb-8 flex gap-2">
        <NotificationFilter
          count={count}
          setActiveIndex={setActiveIndex}
          activeIndex={activeIndex}
        />
      </div>
      <NotificationsList
        as="all"
        setIsLoading={setIsLoading}
        page={page}
        handlePage={setPage}
        data={dataList}
        itemsPerPage={10}
        autoHeight={true}
        loadingItemsToDisplay={isLoading}
        notificationsList={notificationsList}
      />
    </div>
  );
};

export default PersonalNotifications;
