import { Avatar, Button, Box, Typography } from '@mui/material';
import { ChangeEvent, useContext, useRef } from 'react';
import RemoveIcon from '../../../features/icons/removeIcon';
import UpdateIcon from '../../../features/icons/updateIcon';
import { http } from '../../../libs/axios';
import { toast } from 'react-toastify';
import Context from '../../../app/providers/user';
import { mutate } from 'swr';
import { useTranslation } from 'react-i18next';
import { useAuth, UserPayload } from '../../../hooks/useAuth';
import InvalidImageException from '../../../helpers/imageValidator/InvalidImageException';
import { useThemeMode } from '../../../hooks/useThemeMode';
import ImageValidator from '../../../helpers/imageValidator';
import { confirmDelete } from '../../properties/utils';

function UpdateProfilePhoto() {
  const { update } = useAuth();

  const { user, key: queryKey } = useContext(Context);

  const fileRef = useRef<HTMLInputElement>(null);

  const { t } = useTranslation();

  const onPhotoClick = () => {
    fileRef.current && fileRef.current.click();
  };

  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      if (!e.target.files || !e.target.files[0]) return;

      const file = e.target.files[0];

      const validator = new ImageValidator({ file });

      validator.validate({ maxSize: 3 });

      const formData = new FormData();

      formData.append('file', file);

      await http.post(`/users/profile/avatar`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      mutate<UserPayload>(queryKey).then((user) => {
        user && update({ avatar: user.avatar });
      });

      toast.success(t('alerts:user.success.photo') as string);
    } catch (e: unknown) {
      if (e instanceof InvalidImageException) {
        toast.error(t(`alerts:file.error.${e.type}`) as string);
      }
    }
  };
  const handleDelete = async () => {
    try {
      await http.post('/users/profile/avatar/remove', {});

      mutate(queryKey);

      update({ avatar: undefined });

      toast.success(t('alerts:user.success.updated') as string);
    } catch (e: unknown) {
      /* tslint:disable:no-empty */
    }
  };

  const { mode } = useThemeMode();

  const updateIconColor = mode === 'light' ? '#1D1D1F' : '#E6E8EC';

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          gap: { xs: '10px', md: '20px' },
        }}
      >
        <Typography
          variant="h5"
          className="!text-lg sm:!text-2xl !mb-8 lg:!mb-0"
          sx={{
            maxWidth: 240,
            width: '100%',
          }}
        >
          {t('usersPage:profilePhoto')}:
        </Typography>

        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            alignItems: { xs: 'center', sm: 'flex-start' },
            gap: 3,
          }}
        >
          <Avatar
            sx={{
              width: 100,
              height: 100,
            }}
            variant="rounded"
            onClick={onPhotoClick}
            src={user.avatar}
            className="cursor-pointer"
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
            className="lg:pl-2"
          >
            <input
              ref={fileRef}
              onChange={handleChange}
              type="file"
              id="update-avatar"
              className="hidden"
              accept="image/*"
            />

            <label
              className={`flex justify-start items-center gap-2 px-2 rounded cursor-pointer py-[5px] text-sm leading-4  border  bg-transparent mb-3 border-[${updateIconColor}70] text-[${updateIconColor}] hover:border-[${updateIconColor}90]`}
              htmlFor="update-avatar"
            >
              <UpdateIcon />
              {t('usersPage:update')}
            </label>

            <Button
              sx={{
                fontSize: '14px',
                lineHeight: '16px',
                color: user?.avatar ? '#F50057' : '#1D1D1F60',
                borderColor: user?.avatar
                  ? '#F50057 !important'
                  : '#E6E8EC60  !important',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                backgroundColor: 'transparent',
                '&:hover': {
                  borderColor: '#E6E8EC90',
                },
              }}
              onClick={() => confirmDelete(handleDelete)}
              disabled={!user?.avatar}
              variant="outlined"
              startIcon={
                <RemoveIcon color={user?.avatar ? '#F50057' : '#1D1D1F60'} />
              }
            >
              <p> {t('usersPage:delete')}</p>
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default UpdateProfilePhoto;
