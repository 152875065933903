import React from 'react';
import WidgetWrapper from './wrapper';
import PriceIcon from '../../../features/icons/priceIcon';
import { Link } from 'react-router-dom';
import { WidgetProps } from '../index';
import { IDashboardProperty } from '../../../types/user';
import { useTranslation } from 'react-i18next';
import { formatPriceWithCurrency } from '../utils/useOffersListColumns';
import { Tooltip } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
const Item = (props: { item: IDashboardProperty }) => {
  const { item } = props;
  return (
    <div className="self-stretch pb-2 border-b border-gray-200 grid grid-cols-3 gap-4">
      <Link
        className={
          'grow shrink basis-0 text-blue-800 text-xs sm:text-sm font-medium leading-none'
        }
        to={`/properties/${item.id}`}
      >
        <Tooltip title={item.reference} arrow placement="top">
          <span className="block whitespace-nowrap lg:truncate">
            {item.reference}
          </span>
        </Tooltip>
      </Link>
      <Tooltip title={item.city} arrow placement="top">
        <div className="grow shrink basis-0 text-zinc-900 text-xs sm:text-sm font-normal leading-1 lg:truncate">
          {item.city}
        </div>
      </Tooltip>
      <Tooltip
        title={formatPriceWithCurrency(item.price, item.currency)}
        arrow
        placement="top"
      >
        <div className="grow shrink basis-0 h-4 justify-start items-start gap-2 flex">
          <div className="w-4 h-4 relative opacity-30">
            <PriceIcon />
          </div>
          <div className="grow shrink basis-0 text-zinc-900 text-xs sm:text-sm font-normal leading-none whitespace-nowrap lg:truncate">
            {formatPriceWithCurrency(item.price, item.currency)}
          </div>
        </div>
      </Tooltip>
    </div>
  );
};

const PropertiesWidget: React.FC<WidgetProps> = (props) => {
  const { properties } = props.data;
  const { t } = useTranslation('common');
  const isLowerThen520 = useMediaQuery('(max-width:520px)');

  return (
    <WidgetWrapper
      emptyData={!properties.length}
      title={'MyProperties'}
      linkText={'ViewAll'}
      link={'/properties'}
    >
      <div
        className={`w-full flex-col justify-center items-start gap-8 inline-flex ${
          isLowerThen520 ? 'w-[450px]' : 'w-full'
        }`}
      >
        <div className="self-stretch flex-col justify-center items-start gap-2 flex">
          <div className="self-stretch pb-2 border-b border-gray-200 justify-start items-start gap-4 inline-flex">
            <div className="grow shrink basis-0 text-zinc-900 text-sm font-medium leading-none">
              {t('Reference')}
            </div>
            <div className="grow shrink basis-0 text-zinc-900 text-sm font-medium leading-none">
              {t('City')}
            </div>
            <div className="grow shrink basis-0 h-4 justify-start items-start gap-2 flex">
              <div className="grow shrink basis-0 text-zinc-900 text-sm font-medium leading-none">
                {t('Price')}
              </div>
            </div>
          </div>
          {properties.map((item) => (
            <Item key={item.id} item={item} />
          ))}
        </div>
      </div>
    </WidgetWrapper>
  );
};

export default PropertiesWidget;
