import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Tooltip } from '@mui/material';

export const LimitItem = (props: {
  value: number;
  total: number;
  isSmall?: boolean;
}) => {
  const { t } = useTranslation('propertiesPage');
  const progress = props.total
    ? Math.round((props.value / props.total) * 100)
    : 100;

  // Function to determine progress bar color
  const getProgressBarColor = (progress: number): string => {
    if (progress < 50) return '#008000'; // Green for progress less than 50%
    if (progress < 80) return '#eed202'; // Yellow for progress between 50% and 90%
    return '#FF0000'; // Red for progress 90% or more
  };

  // Function to determine status text
  const getProgressStatus = (value: number, total: number): string => {
    if(value === 0) {
      return props.isSmall ? `${t('Left')}: ${total}` : `${t("total_left", { total: props.total })}.`
    }
    if (value >= total) {
      return props.isSmall ? '' : `${t("reached_limit", { total: props.total })}.`;
    }

    const remaining = total - value;
    return props.isSmall ? `${t('Left')}: ${remaining}` : `${t("used_credits", { total: props.total, value: value, remaining: (props.total - props.value) })}`;
  }

  const progressBarColor = getProgressBarColor(progress);

  return (
    <div className={classNames("flex gap-4 items-center w-full", {
      "py-4": !props.isSmall
    })}>
      <div className={classNames('flex-col justify-start gap-2 inline-flex w-full', {
        'items-start': !props.isSmall,
        'items-end': props.isSmall
      })}>
        <div className="flex-col justify-start items-start flex w-full">
          <div className={classNames("w-full flex justify-between font-normal leading-none", {
            'text-xl': !props.isSmall
          })}>
            <span style={{ color: progressBarColor }}>{props.value}</span>
            <span>{props.total}</span>
          </div>
        </div>
        <div className={classNames('relative w-full rounded-[300px] overflow-hidden', {
          'h-1 min-w-[150px]': props.isSmall,
          'h-2': !props.isSmall
        })}>
          <div
            style={{ width: `${progress}%`, maxWidth: "100%", backgroundColor: progressBarColor }}
            className="h-full rounded-[300px] absolute inset-0 z-[2] opacity-70"
          />
          <div
            style={{ width: `100%`, maxWidth: "100%" }}
            className="h-full bg-gray-200 rounded-[300px]"
          />
        </div>
        <div className={'flex gap-2 items-center'}>
          <div className={classNames("font-normal leading-none", {
            "text-base": !props.isSmall,
            "text-xs": props.isSmall
          })}>
            {getProgressStatus(props.value, props.total)}
          </div>
          {props.isSmall && (
            <Tooltip
              arrow
              placement={"top"}
              className={"cursor-pointer"}
              title={t("usage_limit", { total: props.total, value: props.value, remaining: (props.total - props.value) })}
            >
              <InfoOutlinedIcon
                sx={{
                  fill: "#939191",
                  fontSize: '20px'
                }}
              />
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};

const PriceHubbleReportLimit = ({
                                  priceHubbleData
                                }: {
  priceHubbleData: {
    priceHubbleLimit: number,
    totalUsedPriceHubble: number
  }
}) => {
  const { t } = useTranslation('propertiesPage');

  return (
    <div className="flex flex-col gap-8">
      <div>
        <p className="text-[2rem] font-medium mb-2">
          {t('Requirements_title')}.
        </p>
        <p className="text-[1rem]">{t('Requirements_limit')}.</p>
      </div>
      <div className={'flex flex-col divide-y max-w-4xl'}>
        <LimitItem
          total={priceHubbleData.priceHubbleLimit}
          value={priceHubbleData.totalUsedPriceHubble}
        />
      </div>
    </div>
  );
};

export default PriceHubbleReportLimit;
