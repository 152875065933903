import React from 'react';
import { IDashboardOfferItem } from '../../../types/user';
import { Link } from 'react-router-dom';
import TabGridTag from '../../../features/tags/tabGridTag';
import { Skeleton, Tooltip } from '@mui/material';
import PermissionIcon from '../../../features/icons/permissionIcon';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import useLocale from '../../../hooks/useLocale';
import { formatPriceWithCurrency } from '../utils/useOffersListColumns';
import {
  formatDateAndTime,
  getElapsedTimeFromDate,
  sanitizeUrl,
} from '../../../helpers/utils';
import { useTranslation } from 'react-i18next';
import PlusIcon from '../../../features/icons/plusIcon';
import { getInitials } from '../widgets/offers';
import MarkEmailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined';
import { ILeadItem, IPromotionLeadItem } from '../../../types/contacts';
import { useIsRent } from '../../../hooks/useIsRent';
import classNames from 'classnames';
import useSWR, { mutate } from 'swr';
import { OfferType } from '../../../types/pipelines';
import LeadActionsMenu from '../../contacts/singleContactPage/utils/leadActions';
type Props = {
  offerItem?: IDashboardOfferItem;
  leadsItem?: ILeadItem;
  isOffers?: boolean;
  isLeads?: boolean;
  isAgency?: boolean;
};

export default function OfferCrad({
  offerItem,
  leadsItem,
  isOffers,
  isLeads,
  isAgency,
}: Props) {
  const { t } = useTranslation('common');
  const { locale } = useLocale();
  const promoItem = leadsItem?.promotion as IPromotionLeadItem;
  const { data: offerTypes } = useSWR<OfferType[]>('/offer_types');
  const findImages = (): string | undefined => {
    if (Array.isArray(leadsItem?.property?.media)) {
      return undefined;
    }

    const images = leadsItem?.property?.media.images;

    if (images && images.length > 0) {
      const mainImage = images.find((elem) => elem.isMain);
      return mainImage ? mainImage.url.large : images[0]?.url.large;
    }

    return undefined;
  };

  const isRent = useIsRent(leadsItem?.property);
  const inProgress = leadsItem?.status.keyname === 'in progress';
  const skeletonEl = (
    <Skeleton width={100} height={20} animation="wave" variant="text" />
  );
  return (
    <div
      className={classNames('@container p-2 bg-white rounded flex flex-col', {
        'justify-between': !isLeads,
        'justify-start': isLeads
      })}
    >
      <div className={'bg-gray-400 w-full max-h-64 relative'}>
        <div className="h-64 w-full max-w-full">
          {isOffers && (
            <Link
              className="flex w-full h-full"
              to={`/properties/${offerItem?.id}`}
            >
              {offerItem?.dealType && (
                <TabGridTag
                  textContent={offerItem.dealType}
                  textToolTip={offerItem.dealType}
                  className="absolute bg-blue font-medium border-0 font-[Inter] capitalize left-2 top-2"
                  color="white"
                  fontSize="12px"
                />
              )}
              <img
                src={offerItem?.media?.url.large}
                alt=""
                className="w-[100%] !h-[100%] !object-cover max-w-inherit"
              />
            </Link>
          )}
          {isLeads && leadsItem && promoItem ? (
            <Link
              to={leadsItem?.archive ? `/promotions/${promoItem?.id}` : `/promotions/${promoItem?.id}/edit/leads`}
              id={`img_promotion_${promoItem?.id}`}
              className="flex w-full h-full"
            >
              <img
                src={
                  promoItem?.coverImage?.url?.thumbnail ||
                  '/img/default_image.jpg'
                }
                alt=""
                className={classNames("w-[100%] !h-[100%] !object-cover max-w-inherit", {
                  'grayscale': isLeads && leadsItem && leadsItem?.archive
                })}
              />
            </Link>
          ) : (
            !isOffers && (
              <Link
                to={leadsItem?.archive ? `/properties/${leadsItem?.property.id}` : `/properties/${leadsItem?.property.id}/edit/leads`}
                id={`img_property_${leadsItem?.property.id}`}
                className="flex w-full h-full"
              >
                {leadsItem?.property.department.service.keyname && (
                  <TabGridTag
                    textContent={
                      leadsItem?.property?.department.service.keyname
                    }
                    textToolTip={
                      leadsItem?.property?.department.service.keyname
                    }
                    className="absolute bg-blue font-medium border-0 font-[Inter] capitalize left-1 md:left-2 top-2 z-[2]"
                    color="white"
                    fontSize="12px"
                  />
                )}
                <img
                  src={findImages() || '/img/default_image.jpg'}
                  alt=""
                  className={classNames("w-[100%] !h-[100%] !object-cover max-w-inherit", {
                    'grayscale': isLeads && leadsItem && leadsItem?.archive
                  })}
                />
              </Link>
            )
          )}

          {isLeads && promoItem ? (
            <div className=" bg-white rounded-md justify-start items-center gap-1 flex absolute left-2 bottom-2">
              <div
                className="flex gap-1 py-1 px-1 rounded items-center min-w-[112px] max-w-[112px]"
                style={{ border: '1px solid #3446B9 ' }}
              >
                <PermissionIcon
                  className="w-[25px] col-span-1"
                  color="#3446B9"
                />
                <span className="text-blue inter block truncate font-normal text-sm">
                  {promoItem?.reference}
                </span>
              </div>
            </div>
          ) : leadsItem?.property?.agencyReference ? (
            <Tooltip
              title={`${t('propertiesPage:Reference_Agency')}: ${
                leadsItem?.property?.agencyReference
              }`}
              arrow
              placement="top"
            >
              <div className=" bg-white rounded-md justify-start items-center gap-1 flex absolute left-1 sm:left-2 bottom-2 w-[30vw] sm:max-w-[125px]">
                <div
                  className="flex gap-1 py-1 px-1 rounded items-center w-full h-[32px]"
                  style={{ border: '1px solid #00bfff' }}
                >
                  <PermissionIcon
                    className="w-[25px] col-span-1"
                    color="#00bfff"
                  />
                  <span className="text-[#00bfff] block overflow-x-auto whitespace-nowrap md:truncate inter font-normal text-sm">
                    {leadsItem.property?.agencyReference}
                  </span>
                </div>
              </div>
            </Tooltip>
          ) : (
            <div className=" bg-white rounded-md justify-start items-center gap-1 flex absolute left-2 bottom-2">
              <div
                className="flex gap-1 py-1 px-1 rounded items-center min-w-[112px] max-w-[112px]"
                style={{ border: '1px solid #3446B9 ' }}
              >
                <PermissionIcon
                  className="w-[25px] col-span-1"
                  color="#3446B9"
                />
                <span className="text-blue inter block truncate font-normal text-sm">
                  {leadsItem?.property?.reference}
                </span>
              </div>
            </div>
          )}

          {isOffers && offerItem?.reference && (
            <div className=" bg-white rounded-md justify-start items-center gap-1 flex absolute left-2 bottom-2">
              <div
                className="flex gap-1 py-1 px-1 rounded items-center min-w-[112px] max-w-[112px]"
                style={{ border: '1px solid #3446B9 ' }}
              >
                <PermissionIcon
                  className="w-[25px] col-span-1"
                  color="#3446B9"
                />{' '}
                <span className="text-blue inter block truncate font-normal text-sm">
                  {offerItem?.reference}
                </span>
              </div>
            </div>
          )}

          {isLeads && leadsItem && !leadsItem?.archive && (
            <div>
              {leadsItem?.hasSentEmail && (
                <Tooltip
                  arrow
                  placement="top"
                  title={`${t('propertiesPage:Email_sent')}`}
                >
                  <div
                    className={
                      'flex items-center rounded bg-gray-100 py-0.5 px-1 absolute right-2 bottom-10'
                    }
                  >
                    <MarkEmailReadOutlinedIcon
                      fontSize="small"
                      className={'opacity-60'}
                    />
                  </div>
                </Tooltip>
              )}
              <div
                className={classNames(
                  'px-2 py-1 bg-neutral-100 rounded border justify-start h-[32px] w-[30vw] sm:max-w-[125px] items-center gap-1 inline-flex absolute right-1 sm:right-2 bottom-2',
                  {
                    'border-[#4CAF50]': !inProgress,
                    'border-[#F50057]': inProgress,
                  }
                )}
              >
                <div
                  className={classNames(
                    'text-sm overflow-ellipsis font-medium w-full',
                    {
                      'text-[#4CAF50]': !inProgress,
                      'text-[#F50057]': inProgress,
                    }
                  )}
                >
                  <span className="block overflow-x-auto whitespace-nowrap md:truncate">
                    {t(
                      !inProgress ? 'common:processed' : 'common:not_processed'
                    )}
                  </span>
                </div>
              </div>
            </div>
          )}

          {isOffers && (
            <div className="px-2 py-1 bg-neutral-100 rounded absolute right-2 top-2">
              <div className="text-green-500 text-sm font-medium font-[Inter]  normal-case">
                {
                  offerTypes?.find((t) => t.id === Number(offerItem?.offerType))
                    ?.translations?.[locale]?.name
                }
              </div>
            </div>
          )}

          {isLeads && leadsItem?.status.keyname === 'in progress' && !leadsItem?.archive ? (
            <div className=" bg-neutral-100 rounded absolute right-1 md:right-2 top-2">
              <div className="px-2 py-1 rounded border border-orange-600 justify-start items-center gap-1 inline-flex">
                <div className="text-orange-600 text-xs md:text-sm font-normal leading-none">
                  {t('New_lead')}
                </div>
              </div>
            </div>
          ) : null}
          {isLeads && leadsItem?.archive ? (
            <div className=" bg-neutral-100 rounded absolute right-1 md:right-2 top-2">
              <div className="px-2 py-1 rounded border border-[#F50057] justify-start items-center gap-1 inline-flex">
                <ArchiveOutlinedIcon
                  sx={{
                    color: '#F50057',
                    fontSize: '16px'
                  }}
                />
                <div className="text-[#F50057] text-xs md:text-sm font-normal leading-none">
                  {t('common:Archived')}
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>

      <div>
        <div className=" pb-3 pt-4 border-b w-full">
          <div className="flex flex-col gap-3">
            {offerItem &&
            offerItem?.title &&
            offerItem?.title[locale] &&
            offerItem?.title?.[locale]?.title ? (
              <Link
                className={'inline-flex w-full'}
                to={`/properties/${offerItem?.id}`}
                id={`img_property_${offerItem?.id}`}
              >
                <Tooltip
                  title={offerItem?.title?.[locale]?.title}
                  arrow
                  placement="top"
                >
                  <p
                    className={
                      'w-full lg:truncate text-sm md:text-lg font-medium leading-[120%] font-[Inter] overflow-ellipsis overflow-hidden'
                    }
                  >
                    {offerItem?.title?.[locale]?.title}
                  </p>
                </Tooltip>
              </Link>
            ) : null}

            {isLeads && promoItem ? (
              <Link
                className={'inline-flex w-full'}
                to={`/promotions/${promoItem?.id}`}
                id={`img_promotion_${promoItem?.id}`}
              >
                <Tooltip
                  title={promoItem?.translations?.[locale]?.title}
                  arrow
                  placement="top"
                >
                  <p
                    className={
                      'w-full lg:truncate text-sm md:text-lg font-medium leading-[120%] font-[Inter] overflow-ellipsis overflow-hidden'
                    }
                  >
                    {promoItem?.translations?.[locale]?.title}
                  </p>
                </Tooltip>
              </Link>
            ) : leadsItem?.property &&
              leadsItem?.property.translations &&
              leadsItem?.property.translations[locale] &&
              leadsItem?.property?.translations?.[locale]?.title ? (
              <Link
                className={'inline-flex w-full'}
                to={`/properties/${leadsItem?.property.id}`}
                id={`img_property_${leadsItem?.property.id}`}
              >
                <Tooltip
                  title={leadsItem?.property?.translations?.[locale]?.title}
                  arrow
                  placement="top"
                >
                  <p
                    className={
                      'w-full lg:truncate text-sm md:text-lg font-medium leading-[120%] font-[Inter] overflow-ellipsis overflow-hidden'
                    }
                  >
                    {leadsItem?.property?.translations?.[locale]?.title}
                  </p>
                </Tooltip>
              </Link>
            ) : null}

            <div className="font-[Inter] font-medium text-sm leading-[130%]">
              {isOffers
                ? formatPriceWithCurrency(
                    offerItem?.price,
                    offerItem?.currency
                  ) + ' -'
                : isLeads && promoItem
                ? promoItem?.propertiesMinPrice ||
                  (promoItem?.propertiesMaxPrice && (
                    <>
                      {promoItem?.propertiesMinPrice && (
                        <Tooltip
                          title={t('propertiesPage:Min_Price')}
                          arrow
                          placement="top"
                        >
                          <span>
                            {formatPriceWithCurrency(
                              promoItem?.propertiesMinPrice,
                              promoItem?.propertiesCurrency ?? ''
                            )}{' '}
                            {' - '}
                          </span>
                        </Tooltip>
                      )}

                      {promoItem?.propertiesMaxPrice && (
                        <Tooltip
                          title={t('propertiesPage:Max_Price')}
                          arrow
                          placement="top"
                        >
                          <span>
                            {formatPriceWithCurrency(
                              promoItem?.propertiesMaxPrice,
                              promoItem?.propertiesCurrency ?? ''
                            )}
                          </span>
                        </Tooltip>
                      )}
                    </>
                  ))
                : isLeads && isRent
                ? formatPriceWithCurrency(
                    leadsItem?.property.pricingRent?.displayedPrice,
                    leadsItem?.property?.pricingRent?.currency?.value
                  ) + ' -'
                : formatPriceWithCurrency(
                    leadsItem?.property.pricingBuy?.displayedPrice,
                    leadsItem?.property?.pricingBuy?.currency?.value
                  ) + ' -'}
            </div>
          </div>
        </div>
        <div className="py-3 border-b w-full">
          {isOffers && (
            <div>
              <div className="flex gap-2 items-center">
                <PlusIcon color="#6E6E72" />
                <span className="font-medium leading-4 text-xs font-[Inter] text-[#6E6E72]">
                  {t('date_created')}:
                </span>
              </div>
              <span className="font-medium leading-4 text-xs font-[Inter] text-[#1D1D1F]">
                {offerItem?.offerDate
                  ? formatDateAndTime(offerItem?.offerDate)
                  : skeletonEl}
              </span>
            </div>
          )}

          {isLeads && (
            <div className="flex gap-1">
              <span className="font-medium leading-4 text-sm font-[Inter] text-[#6E6E72]">
                {t('Date')}:
              </span>
              <div
                className={classNames(
                  'self-stretch justify-start items-center gap-1 inline-flex',
                  {
                    'text-[#3446B9]': leadsItem?.status.keyname === 'processed',
                    'text-orange-600':
                      leadsItem?.status.keyname === 'in progress',
                  }
                )}
              >
                <div className="text-[14px] md:text-[16px] font-[Inter] font-normal leading-none">
                  {leadsItem &&
                    getElapsedTimeFromDate(leadsItem?.date, locale === 'fr')}
                </div>
              </div>
            </div>
          )}
        </div>
        {isOffers && (
          <div className=" pt-3 w-full">
            <div className="flex items-center gap-3">
              <div className=" bg-blue-800 rounded-full w-[40px] h-[40px] flex items-center justify-center ">
                <div className="text-white text-sm font-normal font-['Inter'] leading-none">
                  {offerItem && getInitials(offerItem.name)}
                </div>
              </div>
              <div>
                <p
                  title={offerItem?.name}
                  className="grow shrink basis-0 text-[#1D1D1F] text-sm font-medium leading-[130%] font-['Inter'] whitespace-nowrap overflow-hidden overflow-ellipsis"
                >
                  {offerItem?.name}
                </p>
                <span className="font-medium text-[#6E6E72] font-[Inter] text-sm leading-4 ">
                  {t('Agency')}
                </span>
              </div>
            </div>
          </div>
        )}
        {isLeads && isAgency && promoItem ? (
          <div className="py-3 border-b w-full">
            <div className="flex gap-1 items-center">
              <span className="font-medium leading-4 text-sm font-[Inter] text-[#6E6E72]">
                {t('Agency')}:
              </span>
              {promoItem?.departments.map(
                (elem: { name: string }, index: number) => {
                  const isLast = index === promoItem.departments.length - 1;
                  return (
                    <Tooltip
                      key={index}
                      title={elem.name}
                      arrow
                      placement="top"
                    >
                      <p className="font-normal text-[14px] md:text-[16px] font-[Inter] truncate w-[73%]">
                        {elem.name + (isLast ? '' : ',')}
                      </p>
                    </Tooltip>
                  );
                }
              )}
            </div>
          </div>
        ) : (
          !isOffers && (
            <div className="py-3 border-b w-full">
              <div className="flex gap-1 items-center">
                <span className="font-medium leading-4 text-sm font-[Inter] text-[#6E6E72]">
                  {t('Agency')}:
                </span>
                <Tooltip
                  title={leadsItem?.property?.department.name}
                  arrow
                  placement="top"
                >
                  <p className="font-normal text-[14px] md:text-[16px] font-[Inter] truncate w-[73%]">
                    {leadsItem?.property?.department.name}
                  </p>
                </Tooltip>
              </div>
            </div>
          )
        )}
        {isLeads && leadsItem?.agentInCharge && (
          <div className="py-3 border-b w-full">
            <div className="flex gap-1 items-center">
              <span className="font-medium leading-4 text-sm font-[Inter] text-[#6E6E72]">
                {t('propertiesPage:Agent')}:
              </span>
              <div>
                <p
                  className={
                    'max-w-full text-[14px] md:text-[16px] overflow-ellipsis overflow-hidden'
                  }
                >
                  {leadsItem?.agentInCharge?.fullname}
                </p>
              </div>
            </div>
          </div>
        )}
        {isLeads && (
          <div className="py-3 border-b w-full">
            <div className="flex gap-1 items-center">
              <span className="font-medium leading-4 text-sm font-[Inter] text-[#6E6E72]">
                {t('Contact')}:
              </span>
              <div>
                <Link
                  to={`/contacts/${leadsItem?.contact?.id}/contact-information`}
                  id={`img_contact_${leadsItem?.contact?.id}`}
                  className={
                    'max-w-full font-[Inter] font-normal overflow-hidden'
                  }
                >
                  <p
                    className={
                      'max-w-full text-[14px] md:text-[16px] overflow-ellipsis overflow-hidden'
                    }
                  >
                    {leadsItem?.contact?.fullname}
                  </p>
                </Link>
              </div>
            </div>
          </div>
        )}

        {isLeads && (
          <div className="py-3 border-b w-full">
            <div className="flex gap-1 items-center">
              <span className="font-medium leading-4 text-sm font-[Inter] text-[#6E6E72]">
                {t('Source')}:
              </span>
              <div className="w-full">
                {leadsItem?.source === 'Preview Page' ? (
                  <Tooltip title={leadsItem?.source} arrow placement="top">
                    <a
                      target={'_blank'}
                      rel={'noreferrer'}
                      href={
                        leadsItem?.source === 'Preview Page' && promoItem
                          ? `/property/${promoItem?.reference}?broker=true`
                          : leadsItem?.property && leadsItem?.property.reference
                          ? `/property/${leadsItem?.property.reference}?broker=true`
                          : sanitizeUrl(leadsItem?.source)
                      }
                      className={` inline-flex gap-1 transition-opacity duration-300 hover:opacity-80 overflow-hidden text-ellipsis whitespace-nowrap items-end`}
                    >
                      <span className=" w-[87%] block text-[14px] md:text-[16px] font-[Inter] font-normal truncate">
                        {leadsItem?.source}
                      </span>
                    </a>
                  </Tooltip>
                ) : (
                  <Tooltip
                    title={
                      leadsItem?.source.includes('Website')
                        ? leadsItem?.source.replace('Website', t('Website'))
                        : t(`${leadsItem?.source}`)
                    }
                    arrow
                    placement="top"
                  >
                    <div className="overflow-hidden text-ellipsis whitespace-nowrap flex items-end">
                      <span className="w-[87%] block text-[14px] md:text-[16px] font-[Inter] font-normal truncate">
                        {leadsItem?.source.includes('Website')
                          ? leadsItem?.source.replace('Website', t('Website'))
                          : t(`${leadsItem?.source}`)}
                      </span>
                    </div>
                  </Tooltip>
                )}
              </div>
            </div>
          </div>
        )}

        {isLeads && leadsItem?.message && (
          <div className="py-3 w-full">
            <div className="flex gap-1 items-center">
              <span className="font-medium leading-4 text-sm font-[Inter] text-[#6E6E72]">
                {t('Message')}:
              </span>
              <Tooltip title={leadsItem?.message} arrow placement="top">
                <p className="font-normal text-[14px] md:text-[16px] font-[Inter] truncate w-[95%]">
                  {leadsItem?.message}
                </p>
              </Tooltip>
            </div>
          </div>
        )}

        {isLeads && leadsItem?.property && !leadsItem?.archive && (
          <div className=" border-t">
            <div className="w-[14%] mx-auto">
              <LeadActionsMenu
                row={{
                  ...leadsItem,
                  parentId: leadsItem?.contact
                    ? leadsItem.contact.id.toString()
                    : '',
                }}
                mutateForStatus={mutate}
                withSendFiles
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
