import { Box, Tab, Tabs, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useThemeMode } from '../../hooks/useThemeMode';
import UpdatePassword from './updatePassword';
import UpdateUserInfos from './updateUserInfos';
import AiSetting from './aiSettings';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function ProfileSettings() {
  const [currentTab, setCurrentTab] = useState(0);
  const { mode } = useThemeMode();

  const { t } = useTranslation('usersPage');

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        color: 'text.primary',
        bgColor: 'background.default',
      }}
    >
      <Typography className="!text-3xl"> {t('profileSettings')} </Typography>
      <Box
        sx={{
          borderWidth: '1px',
          borderColor: mode === 'dark' ? '#334155' : '#e5e7eb',
        }}
      >
        <Box
          sx={{
            width: '100%',
            color: 'text.primary',
            bgColor: 'background.default',
          }}
        >
          <Box
            sx={{
              overflowX: 'auto',
              whiteSpace: 'nowrap',
              width: '100%',
              '&::-webkit-scrollbar': {
                height: '10px',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#BBC0C4',
                borderRadius: '8px',
              },
              '&::-webkit-scrollbar-thumb:hover, &::-webkit-scrollbar-thumb:active':
                {
                  backgroundColor: '#888',
                },
              '&::-webkit-scrollbar-track': {
                backgroundColor: '#f1f1f1',
              },
            }}
          >
            <Tabs
              sx={{
                minWidth: 'max-content',
                '& .MuiTabs-scroller': {
                  overflowX: 'auto',
                },
              }}
              value={currentTab}
              onChange={handleChange}
            >
              <Tab label={t('personalInfo')} {...a11yProps(0)} />
              <Tab label={t('EditPassword')} {...a11yProps(1)} />
              <Tab label={t('propertiesPage:AI_settings')} {...a11yProps(2)} />
            </Tabs>
          </Box>

          <TabPanel value={currentTab} index={0}>
            <UpdateUserInfos />
          </TabPanel>
          <TabPanel value={currentTab} index={1}>
            <UpdatePassword />
          </TabPanel>
          <TabPanel value={currentTab} index={2}>
            <AiSetting />
          </TabPanel>
        </Box>
      </Box>
    </Box>
  );
}

export default ProfileSettings;

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  const { mode } = useThemeMode();

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            p: { xs: 2, sm: 3 },
            color: 'text.primary',
            backgroundColor: mode === 'light' ? '#fff' : 'background.default',
          }}
        >
          {children}
        </Box>
      )}
    </Box>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
