import React from 'react';
import WidgetWrapper from './wrapper';
import { WidgetProps } from '../index';
import { useTranslation } from 'react-i18next';
import { formatPriceWithCurrency } from '../utils/useOffersListColumns';

const AgencyWidget: React.FC<WidgetProps> = (props) => {
  const { agency } = props.data;
  const { t } = useTranslation('common');

  return (
    <WidgetWrapper title={'MyAgency'} linkText={'MyAgency'} link={null}>
      <div className="w-full flex-col justify-center items-start gap-8 inline-flex">
        <div className="self-stretch flex-col justify-center items-center gap-4 flex">
          <div
            className={
              'w-[88px] h-[88px] rounded-full flex items-center justify-center border border-gray-200 overflow-hidden'
            }
          >
            {agency.logo ? (
              <img
                className="w-full h-full object-contain"
                src={agency.logo.x64}
              />
            ) : null}
          </div>
          <div className="flex-col justify-center items-start gap-1 flex">
            <div className="w-full text-center text-zinc-900 text-2xl font-normal leading-7">
              {agency.name}
            </div>
            <div className="w-full text-center text-neutral-500 text-sm font-medium leading-none">
              {agency.location}
            </div>
          </div>
        </div>
        <div className="self-stretch flex-col justify-center items-start gap-2 flex">
          <div className="self-stretch pb-2 border-b border-gray-200  justify-start items-start gap-4 inline-flex">
            <div className="grow shrink md:basis-0 text-zinc-900 text-sm font-normal leading-none w-[60%]">
              {t('ActiveProperties')}
            </div>
            <div className="grow shrink md:basis-0 text-zinc-900 text-sm font-medium leading-none">
              {agency['active-properties']}
            </div>
          </div>
          <div className="self-stretch pb-2 border-b border-gray-200 justify-start items-start gap-4 inline-flex">
            <div className="grow shrink md:basis-0 text-zinc-900 text-sm font-normal leading-none w-[60%]">
              {t('ActiveUsers')}
            </div>
            <div className="grow shrink md:basis-0 text-zinc-900 text-sm font-medium leading-none">
              {agency['active-users']}
            </div>
          </div>
          <div className="self-stretch pb-2 border-b border-gray-200 justify-between items-start gap-4 inline-flex">
            <div className="grow shrink md:basis-0 whitespace-nowrap text-zinc-900 text-sm font-normal leading-none w-[60%]">
              {t('TotalSoldPrice')}
            </div>
            <div className="grow shrink md:basis-0 text-zinc-900 text-sm font-medium leading-none whitespace-nowrap lg:truncate">
              {formatPriceWithCurrency(
                agency['total-sold-price'],
                agency.currency
              )}
            </div>
          </div>
          <div className="self-stretch pb-2 border-b border-gray-200 justify-between items-start gap-4 inline-flex">
            <div className="grow shrink md:basis-0 whitespace-nowrap text-zinc-900 text-sm font-normal leading-none w-[60%]">
              {t('TotalCommission')}
            </div>
            <div className="grow shrink md:basis-0 text-zinc-900 text-sm font-medium leading-none whitespace-nowrap lg:truncate">
              {formatPriceWithCurrency(
                agency['total-commission'],
                agency.currency
              )}
            </div>
          </div>
        </div>
      </div>
    </WidgetWrapper>
  );
};

export default AgencyWidget;
