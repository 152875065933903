import TableGridX from '../../../components/tableGridX';
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
} from '@mui/x-data-grid';
import useTranslationForTableGridXConfig from '../../../hooks/useTranslationForTableGridX';
import { useTranslation } from 'react-i18next';
import {
  usersListingConfig,
  usersListingConfigForAgent,
} from '../../../components/material-ui/tablesConfig/usersListingConfig';
import {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { ApiListing } from '../../../types/api';
import User from '../../../types/user';
import { Box, Button, IconButton, InputAdornment } from '@mui/material';
import { useAuth } from '../../../hooks/useAuth';
import InputFormField from '../../../components/form/inputFormField';
import SearchIcon from '../../../features/icons/searchIcon';
import CloseIcon from '../../../features/icons/closeIcon';
import SpinnerLoading from '../../../features/spinner/spinnerLoading';
import { http } from '../../../libs/axios';
import { toast } from 'react-toastify';
import ExportPopup from '../../agencies/tree/utils/exportPopup';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
type Props = PropsWithChildren & {
  data: ApiListing<User>;
  itemsPerPage: number;
  page: number;
  handlePage: (page: number) => void;
  setNumberUsers: (value: number) => void;
  urlRef: string;
};

const UsersListingTable: FunctionComponent<Props> = ({
  data,
  itemsPerPage,
  setNumberUsers,
  page,
  handlePage,
}) => {
  const { t } = useTranslation('usersPage');
  const [configList, setConfigList] = useState<any>([{}]);
  const [dataSearch, setDataSearch] = useState<ApiListing<User> | null>();
  const [userCount, setUserCount] = useState<number>(0);
  const [loading, setIsLoading] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [search, setSearch] = useState<string>('');
  const [searchAgency, setSearchAgency] = useState<string>('');

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messageColor, setMessageColor] = useState('');

  const { isAdmin } = useAuth();

  const rows = Array.isArray(dataSearch)
    ? dataSearch
    : data['hydra:member'] || [];
  const rowCount = userCount ? userCount : data['hydra:totalItems'];
  setNumberUsers(rowCount);
  useEffect(() => {
    if (isAdmin) {
      setConfigList(usersListingConfigForAgent);
    } else {
      setConfigList(usersListingConfig);
    }
  }, [isAdmin]);

  const handleSearch = async () => {
    setIsLoading(true);

    try {
      const res = await http.get(
        `/users/magic_field?searchQuery=${search}&agence=${searchAgency}`
      );
      setDataSearch(res.data['hydra:member']);
      setUserCount(res.data['hydra:totalItems']);
      toast.success(`${t('Search_completed_successfully')}`, {
        position: 'bottom-center',
        autoClose: 3000,
      });
    } catch (error) {
      console.log(error);
      toast.error(`${t('An_error_occurred_while_performing_the_search')}`, {
        position: 'bottom-center',
        autoClose: 3000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const exportUsers = async () => {
    setLoadingUsers(true);

    try {
      await http.get('/users/export');

      setMessage(
        'Users_have_been_exported_successfully_You_will_receive_an_email_shortly'
      );
      setMessageColor('#4CAF50');
    } catch (error) {
      console.log(error);

      setMessage('An_error_occurred_while_exporting_the_users');
      setMessageColor('red');
    } finally {
      setLoadingUsers(false);
    }

    setOpen(true);
    setTimeout(() => setOpen(false), 5000);
  };

  const usersListingColumns = useTranslationForTableGridXConfig([
    configList,
    'usersPage',
  ]);

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  if (!data) return null;

  function CustomToolbar() {
    return (
      <GridToolbarContainer
        sx={{ bgcolor: 'background.paper', color: 'text.primary' }}
      >
        <GridToolbarColumnsButton
          placeholder=""
          onPointerEnterCapture={() => null}
          onPointerLeaveCapture={() => null}
        />
        {/* <GridToolbarFilterButton
          placeholder=""
          onPointerEnterCapture={() => null}
          onPointerLeaveCapture={() => null}
        /> */}

        {/* <GridToolbarExport
          printOptions={{ disableToolbarButton: true }}
          csvOptions={{
            fileName: `${t('REALS_EXPORT_USERS')} - ${new Date()
              .toISOString()
              .replace('T', ' ')
              .slice(0, 10)}`,
            delimiter: ';',
            utf8WithBom: true,
          }}
        /> */}
      </GridToolbarContainer>
    );
  }

  return (
    <Box className="w-full h-full">
      <div className="flex flex-col sm:flex-row gap-2 sm:gap-0 justify-between my-2">
        <Button
          onClick={exportUsers}
          disabled={loadingUsers}
          disableElevation
          sx={{
            px: 1.5,
            borderRadius: 1,
            border: '1px solid #1E40AF !important',
          }}
        >
          {loadingUsers ? (
            <SpinnerLoading />
          ) : (
            <Box className="flex justify-center items-center" gap="10px">
              <div>
                <SaveAltIcon fontSize="small" />
              </div>
              <div className="text-sm font-medium leading-none">
                {t('propertiesPage:Export')}
              </div>
            </Box>
          )}
        </Button>
        <div className="flex flex-col sm:flex-row justify-between gap-4 sm:w-[67%]">
          <InputFormField
            className="bg-white"
            placeholder={t('First_name_last_name_email_phone')}
            isSmall
            InputProps={{
              endAdornment: search && (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    onClick={() => {
                      setSearch('');
                      setDataSearch(null);
                      setUserCount(0);
                    }}
                    aria-label={t('clear')}
                  >
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            onKeyPress={handleKeyPress}
          />
          <InputFormField
            className="bg-white"
            placeholder={t('Agency')}
            isSmall
            InputProps={{
              endAdornment: searchAgency && (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    onClick={() => {
                      setSearchAgency('');
                      setDataSearch(null);
                      setUserCount(0);
                    }}
                    aria-label={t('clear')}
                  >
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={searchAgency}
            onChange={(e) => setSearchAgency(e.target.value)}
            onKeyPress={handleKeyPress}
          />
          <Button
            className="w-full sm:w-[45%] md:w-[40%] lg:w-[30%] xl:w-[20%]"
            onClick={handleSearch}
            disabled={loading}
            disableElevation
            variant="contained"
            sx={{
              px: 2,
              py: 1,
              borderRadius: 1,
              border: '1px solid #1E40AF !important',
            }}
          >
            {loading ? (
              <SpinnerLoading />
            ) : (
              <Box className="flex justify-center items-center" gap="10px">
                <div>
                  <SearchIcon color="white" />
                </div>
                <div className="text-sm font-medium leading-none">
                  {t('Search')}
                </div>
              </Box>
            )}
          </Button>
        </div>
      </div>
      <TableGridX
        rows={rows}
        columns={usersListingColumns}
        autoPageSize
        pageSize={itemsPerPage}
        page={page - 1}
        paginationMode="server"
        components={{ Toolbar: CustomToolbar }}
        rowCount={rowCount}
        onPageChange={(page: number) => {
          handlePage(page + 1);
        }}
      />
      <ExportPopup
        messageColor={messageColor}
        message={message}
        open={open}
        handleClose={() => setOpen(false)}
      />
    </Box>
  );
};

export default UsersListingTable;
