import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import {
  MultipagePropsGenerator,
  PreviewSubjectTypes,
  PropertyWidgetPreviewProps,
} from '../../../../../types/brochureEditor';
import { DistanceInfo, IPropertyApi } from '../../../../../types/property';

import WidgetTitle from '../../widgetTitle';

const MultipageDistancesWidget = ({
  multipageProps = {},
  widgetOptions,
}: PropertyWidgetPreviewProps) => {
  const { t } = useTranslation('propertiesPage');

  const { index, distances = [] } = multipageProps as DistancePage;

  return (
    <div className="flex flex-col gap-4">
      <WidgetTitle
        title={t('Distances')}
        widgetOptions={widgetOptions}
        pageIndex={index}
      />

      <div className="flex flex-col gap-6">
        {distances.map(({ key, items }, index) => (
          <div key={index} className="flex flex-col gap-2">
            <h3
              className="text-lg font-semibold uppercase border-b border-gray-200"
              style={{ color: 'var(--brand-color)' }}
            >
              {t(key)}
            </h3>
            <DistanceTable lines={items} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default MultipageDistancesWidget;

const PAGE_HEIGHT = 1100;

export const generateProps: MultipagePropsGenerator = async ({
  originalSubject,
  type,
}) => {
  if (!originalSubject || type !== PreviewSubjectTypes.PROPERTY) {
    return null;
  }
  const property = originalSubject as IPropertyApi;
  const { distances } = property;
  if (!distances) {
    return null;
  }

  const pages: DistancePage[] = [];
  let totalPageHeight = 0;
  let thisPageGroups: DistanceGroup[] = [];
  let thisPageIndex = 0;
  for (const [key, items] of Object.entries(distances)) {
    const groupHeight = calculateGroupHeight(items);
    if (
      totalPageHeight !== 0 &&
      totalPageHeight + groupHeight > PAGE_HEIGHT + 24
    ) {
      pages.push({
        distances: thisPageGroups,
        index: thisPageIndex,
      });
      thisPageGroups = [];
      totalPageHeight = 0;
      thisPageIndex++;
    }
    thisPageGroups.push({ key, items });
    totalPageHeight += groupHeight;
  }
  pages.push({
    distances: thisPageGroups,
    index: thisPageIndex,
  });

  return pages;
};

function calculateGroupHeight(lines: DistanceInfo[]) {
  const titleHeight = 38;
  const headingLineHeight = 48;
  const lineHeight = 48;
  const marginAfter = 24;
  const numLines = lines.length;
  return titleHeight + headingLineHeight + numLines * lineHeight + marginAfter;
}

function DistanceTable({ lines }: { lines: DistanceInfo[] }) {
  const { t } = useTranslation('propertiesPage');

  const columns: {
    field: keyof DistanceInfo;
    headerName: string;
    suffix?: string;
    className?: string;
  }[] = [
    {
      field: 'name',
      headerName: t('Name'),
      suffix: '',
      className: 'w-2/5',
    },
    {
      field: 'distance',
      headerName: t('Distance'),
      suffix: 'm',
      className: 'text-right',
    },
    {
      field: 'foots',
      headerName: t('By_foot'),
      suffix: 'min',
      className: 'text-right',
    },
    {
      field: 'publicTransport',
      headerName: t('By_public_transport'),
      suffix: 'min',
      className: 'text-right',
    },
    {
      field: 'car',
      headerName: t('By_car'),
      suffix: 'min',
      className: 'text-right',
    },
  ];

  return (
    <table>
      <thead>
        <tr>
          {columns.map((col) => (
            <th
              key={col.field}
              className={classNames(
                'text-[16px] leading-none font-medium p-4 text-left',
                col.className
              )}
              style={{
                color: 'var(--brand-color)',
              }}
            >
              {col.headerName}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {lines?.map((line) => (
          <tr key={line.id}>
            {columns.map((col) => {
              return (
                <td
                  key={col.field}
                  className={classNames(
                    'text-[16px] leading-none font-medium p-4 text-left border-t border-gray-200',
                    col.className
                  )}
                >
                  {line[col.field]} {col.suffix}
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

interface DistanceGroup {
  key: string;
  items: DistanceInfo[];
}

interface DistancePage {
  distances: DistanceGroup[];
  index: number;
}
