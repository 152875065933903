import { IAddress } from './address';
import { Translations } from './translations';
import { IPropertyApi } from './property';

interface CompanyTranslations {
  [index: string]: {
    description: string;
    legalName: string;
    locale: string;
  };
}

export interface IContact {
  id: number;
  archive: boolean;
  company: IContactCompany;
  individual: IContactIndividual;
  office?: IOffice;
  idAgence?: number;
  nameAgence?: string;
  agencies: {
    categoryAgence?: string;
    idAgence?: number;
    nameAgence?: string;
    logoAgence?: {
      x32?: string | null;
      x64?: string | null;
      x96?: string | null;
      x368?: string | null;
    } | null;
  }[];
  createdAt?: string;
  updatedAt?: string;
  agentInCharge?: IAgentInCharge;
  fullname?: string;
  constructor: {
    id: number;
    translations: Translations;
  };
  types: {
    id: number;
    keyname: string;
    translations: Translations;
  }[];
  businessType: {
    id: number;
    keyname: string;
  };
  rating: number;
  sourcePortals: {
    id: number;
    keyname: string;
  }[];
  sourceBroker: IContact;
  sourceWebsite: boolean;
  sourceWebsiteName?: string;
  sourceDirect: boolean;
  sourcePreviewPage: boolean;
  sourcePromotionLP: boolean;
  sourcePropertyLP: boolean;
  unsubscribedMarketing?: boolean | null;
  ownerQualifChoice: {
    id: number;
    keyname: string;
    translations: Translations;
  };
  clientQualifChoice: {
    id: number;
    keyname: string;
    translations: Translations;
  };
  publication: {
    private: boolean;
    visibilities: {
      id: number;
      keyname: string;
    }[];
  };
  visibility: { id: number; keyName: string; translations: Translations };
  lead: boolean;
  leads: ILeadItem[];
  status: {
    id: number;
    keyname: string;
    translations: Translations;
  };
  warningField: {
    translations: Translations;
  };
}

export interface IContactListItem {
  id: number;
  archive: boolean;
  company: IContactCompany;
  individual: IContactIndividual;
  office?: IOffice;
  idAgence?: number;
  nameAgence?: string;
  agentInCharge?: IAgentInCharge;
  fullname?: string;
  constructor: {
    id: number;
    translations: Translations;
  };
  types: {
    id: number;
    keyname: string;
    translations: Translations;
  }[];
  businessType: {
    id: number;
    keyname: string;
  };
  rating: number;
  sourcePortals: {
    id: number;
    name: string;
  }[];
  sourceBroker: {
    id: string;
    firstname?: string;
    lastname?: string;
    legalName?: string;
  };
  sourceWebsite: boolean;
  sourceDirect: boolean;
  sourcePreviewPage: boolean;
  sourcePromotionLP: boolean;
  sourcePropertyLP: boolean;
  ownerQualifChoice: {
    id: number;
    keyname: string;
    translations: Translations;
  };
  clientQualifChoice: {
    id: number;
    keyname: string;
    translations: Translations;
  };
  publication: {
    private: boolean;
    visibilities: {
      id: number;
      keyname: string;
    }[];
  };
  visibility: { id: number; keyName: string; translations: Translations };
  lead: boolean;
  leads: ILeadItem[];
  status: {
    id: number;
    keyname: string;
    translations: Translations;
  };
  warningField: {
    translations: Translations;
  };
}

export interface IContactIndividual {
  id?: string | number;
  contactId?: string;
  firstname: string;
  lastname: string;
  phone: string;
  email: string;
  age: string;
  birthdate: string;
  birthplace: string;
  greeting: IContactGreeting;
  nationality: string;
  address: IAddress;
  companyInformation?: {
    id: string;
  };
  translations: Translations;
  language: { id: number; name: string };
}

export interface IContactCompany {
  id: number;
  phone: string;
  email: string;
  website: string;
  legalName: string;
  address: IAddress;
  mainContactPerson: string;
  emailContactPerson: string;
  phonesContactPerson: {
    telephone: string;
  }[];
  business: { id: number; keyname: string } | null;
  translations: CompanyTranslations;
  individuals: IContactIndividual[];
  nameAgence: string;
}

export interface IOffice {
  id: number;
  name: string;
}

export interface IAgentInCharge {
  id: number;
  firstname: string;
  lastname: string;
  avatar?: string | null;
}

export interface IContactType {
  id: number;
  keyname: string;
  choices: IContactTypeChoice[];
  translations: Translations;
}

export interface IContactTypeChoice {
  id: number;
  keyname: string;
  translations: Translations;
}

export interface IContactSearch {
  id?: string;
  contact?: string;
  contactTypes?: string[];
  budgetMin?: number;
  budgetMax?: number;
  surfaceMin?: number;
  surfaceMax?: number;
  roomsMin?: number;
  roomsMax?: number;
  bedroomsMin?: number;
  bedroomsMax?: number;
  bathroomsMin?: number;
  bathroomsMax?: number;
  floor?: number;
  translations?: Translations;
  conveniences?: {
    id: number;
    name: string;
  }[];
  type?: number;
  propertyTypes?: {
    id: number;
    name: string;
    locale: string;
  }[];
  serviceType?: {
    id: number;
    name: string;
  };
  mountainView?: boolean;
  seaView?: boolean;
  lakeView?: boolean;
  balcony?: boolean;
  terrace?: boolean;
  lift?: boolean;
  parking?: boolean;
  garage?: boolean;
  pool?: boolean;
  autoMarketing?: boolean;
  unsubscribedMarketing?: boolean | null;
  addresses?: {
    id: number;
    longitude: number;
    latitude: number;
    polygon: { lat: number; lon: number }[][];
    zipCode: string;
    translations: Translations;
  }[];
}

export interface IContactHistoryItem {
  action: string;
  date: string;
  id: number;
}

export interface IfilterApiType {
  departments?: number[];
  name?: string;
  agents?: number[];
  client: boolean;
  owner: boolean;
  external: boolean;
}

export const initialContactFilterValues = {
  departments: [],
  contactName: '',
  agents: '',
  'owner-types': '',
  'client-types': '',
  'external-types': '',
  'constructor-types': '',
  client: false,
  owner: false,
  external: false,
};

export interface IContactTask {
  id: string | number;
  title: string;
  topic?: string;
  type?: {
    id: number;
    keyname: string;
    translations: Translations;
  } | null;
  time?: string;
  note?: string;
  endDate?: string;
  property?: {
    id: number;
  };
  properties?: {
    id: number;
    translations: Translations;
  }[];
  startDate?: string;
  reminderTime?: string | null;
  status?: {
    id: number;
    keyname: string;
    translations: Translations;
  } | null;
}

export interface IContactComment {
  id: number;
  commentBy: {
    id: string;
    firstname: string;
    lastname: string;
  };
  commentDate: string;
  commentBody: string;
  isPrivate: boolean;
}

export interface ILeadItem {
  createdAt: string;
  createdBy: string;
  date: string;
  archive: boolean;
  agentInCharge: {
    fullname: string;
    id: number;
  };
  id: number;
  message: string;
  property: IPropertyApi;
  hasSentEmail: boolean;
  promotion: IPromotionLeadItem | null;
  source: string;
  updatedAt: string;
  updatedBy: string;
  status: {
    id: number;
    keyname: string;
    translations: Translations;
  };
  type: {
    id: number;
    keyname: string;
    translations: Translations;
  };
  contact: IContact | null;
}
export interface IPromotionLeadItem {
  coverImage: {
    url: {
      large: string;
      mobile: string;
      original: string;
      thumbnail: string;
    };
  };
  propertiesCurrency: string | null;
  propertiesMaxPrice: number | null;
  propertiesMinPrice: number | null;
  departments: {
    service: {
      keyname: string;
    };
    categoryEntity: string;

    name: string;
    logo?: {
      x32?: string | null;
      x64?: string | null;
      x96?: string | null;
      x368?: string | null;
    } | null;
  }[];
  createdAt: string;
  id: number;
  reference: string;
  translations: Translations;
  updatedAt: string;
}

export interface IContactHobby {
  id: number;
  name: string;
  translations: Translations;
}

export interface IContactGreeting {
  id: number;
  keyname: string;
  translations: Translations;
}
