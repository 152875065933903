import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  CircularProgress,
  ClickAwayListener,
  Fade,
  Modal,
} from '@mui/material';

import TranslationIcon from '../../../../../features/icons/translationIcon';
import SettingsIcon from '../../../../../features/icons/settingsIcon';
import UpdateIcon from '../../../../../features/icons/updateIcon';
import SaveAltIcon from '@mui/icons-material/SaveAlt';

import useLocale from '../../../../../hooks/useLocale';
import PopupStepper, { Step } from '../../../../../components/popupStepper';
import LanguageRadioButtons from '../../../../../components/popupStepper/controls/languageRadioButtons';

import PriceHubbleReportSettings from './settings';
import { toast } from 'react-toastify';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import { generatePdfWithOverlayHeaderFooter } from '../utils/generatePdfWithOverlayHeaderFooter';
import { http } from '../../../../../libs/axios';
import ErrorIcon from '@mui/icons-material/Error';
import PriceHubbleReportRequirements from './requirements';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { NameableProperty } from '../../../../../types/property';
import PriceHubbleReportLimit, { LimitItem } from "./limit";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

enum Steps {
  REQUIREMENTS,
  LANGUAGE,
  SETTINGS,
  REPORT,
}

export interface Settings {
  title: string;
  enabled: boolean;
  configurations?: string[];
  switches?: string[];
  keyname: string;
}

const initialSettings: Settings[] = [
  {
    title: 'Market Trends',
    enabled: true,
    keyname: 'market_trends',
    switches: ['old_market_trends', 'new_market_trends'],
  },
  {
    title: 'Amenities',
    enabled: true,
    configurations: ['health', 'leisure', 'catering', 'shopping', 'education'],
    keyname: 'amenities',
  },
  {
    title: 'Comparables',
    enabled: true,
    configurations: [
      'offers',
      'transactions',
      'offer_details',
      'transaction_details',
    ],
    keyname: 'comparables',
  },
  {
    title: 'Socio-Economics',
    enabled: true,
    configurations: ['economics', 'demographics'],
    keyname: 'socio',
  },
  {
    title: 'Environment',
    enabled: true,
    configurations: ['view', 'noise', 'nuisance'],
    keyname: 'environment',
  },
  {
    title: 'Accessibility',
    enabled: true,
    keyname: 'accessibility',
  },
  {
    title: 'Projects',
    enabled: true,
    keyname: 'projects',
  },
  {
    title: 'Attachments: Floorplans and Layouts',
    enabled: true,
    keyname: 'attachments_floorplan',
  },
  {
    title: 'Attachments: Certificates, Regulations and Permits',
    enabled: true,
    keyname: 'attachments_permit',
  },
  {
    title: 'Attachments: Marketing',
    enabled: true,
    keyname: 'attachments_marketing',
  },
  {
    title: 'Attachments: Valuations',
    enabled: true,
    keyname: 'attachments_valuation',
  },
  {
    title: 'Attachments: Others',
    enabled: true,
    keyname: 'attachments_other',
  },
];

const translateSettingsToQueries = (
  settings: Settings[],
  radioSelection: string
): string => {
  const queries = settings
    .filter((setting) => setting.enabled && setting.keyname !== 'market_trends')
    .map((setting) => {
      let query = setting.keyname;

      if (setting.configurations && setting.configurations.length > 0) {
        query += `=${setting.configurations.join(',')}`;
      } else {
        query += `=true`;
      }

      return query;
    });

  if (radioSelection) {
    queries.push(`${radioSelection}=true`);
  }

  // Combine all queries into a single string with '&' delimiter
  return queries.join('&');
};

function isPropertyValid(
  property: any,
  withBathrooms: boolean
): { isValid: boolean; validation: Record<string, boolean> } {
  const { address, constructionYear, livingArea, rooms, bathrooms } = property;

  // Create a validation object to track the validity of each field
  const validation = {
    hasZipCode: !!address?.zipCode,
    hasLatitude: !!address?.latitude,
    hasLongitude: !!address?.longitude,
    hasConstructionYear: !!constructionYear,
    hasLivingArea: !!livingArea,
    hasLivingAreaMoreThatTwenty: livingArea >= 20,
    hasRooms: !!rooms,
    hasBathrooms: true,
  };

  if (withBathrooms) {
    validation.hasBathrooms = !!bathrooms;
  }

  const isValid = Object.values(validation).every(
    (isFieldValid) => isFieldValid
  );

  return { isValid, validation };
}

const CustomErrorMsg = ({ message }: { message: string }) => {
  const { t } = useTranslation('propertiesPage');

  return (
    <div>
      <div className="flex gap-2">
        <ErrorIcon color={'error'} />
        <p className={'text-lg mb-4'}>{t('Could_not_generate_report')}. </p>
      </div>
      <p className={'font-medium mb-1'}>{t('Server_message')}:</p>
      <p>{message}</p>
    </div>
  );
};

const PriceHubbleReportPopup = ({
  property,
  isModalOpen,
  setIsModalOpen,
  withBathrooms,
}: {
  property: NameableProperty;
  isModalOpen: boolean;
  withBathrooms: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { id, reference, priceHubbleData } = property;
  const { locale } = useLocale();
  const [selectedLocale, setSelectedLocale] = useState<string>(locale);
  const [settings, setSettings] = useState(initialSettings);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isGenerated, setIsGenerated] = useState(false);
  const { t } = useTranslation('propertiesPage');

  const { isValid, validation } = isPropertyValid(property, withBathrooms);

  const onClickAway = () => {
    onClose();
  };

  const onClose = () => {
    setIsModalOpen(false);
    setIsGenerating(false);
    setIsGenerated(false);
  };

  const [radioSelection, setRadioSelection] =
    useState<string>('old_market_trends');

  async function generateHubbleReport(
    settingsString: string,
    locale: string,
    fileName: string
  ) {
    setIsGenerating(true);
    setIsGenerated(false);

    try {
      const { data } = await http.get(
        `${process.env.REACT_APP_PDF_SERVICE_URL}/pdf/hubble-report/property/${id}?locale=${locale}&${settingsString}`
      );
      const { pdfPath, agenceLogo, priceHubbleFooterImage } = data;

      await generatePdfWithOverlayHeaderFooter({
        pdfPath: `${process.env.REACT_APP_PDF_SERVICE_URL}/${pdfPath}`,
        logoUrl: agenceLogo,
        footerImage: priceHubbleFooterImage,
        fileName,
      });

      toast.success(`${t('Report_generated_successfully')}` as string);

      setIsGenerated(true);
    } catch (error: any) {
      if (error?.response) {
        toast(
          () => (
            <CustomErrorMsg
              message={`${
                error.response.data.message || t('An_error_occurred')
              }`}
            />
          ),
          {
            position: 'top-right',
            autoClose: 10000,
          }
        );
      } else {
        toast.error(`${t('alerts:unknown_error')}`);
      }

      setIsGenerated(false);
      setIsModalOpen(false);
    }

    setIsGenerating(false);
  }

  const handleNext = (step: Step | undefined) => {
    if (step?.id === Steps.REPORT) {
      const settingsString = translateSettingsToQueries(
        settings,
        radioSelection
      );

      generateHubbleReport(
        settingsString,
        selectedLocale,
        `hubble-report--${reference}.pdf`
      );
    }
  };

  const shouldDisablePrev = (step: Step | undefined) => {
    switch (step?.id) {
      case Steps.REPORT:
        return isGenerating;
        break;
      default:
        return false;
    }
  };

  const steps = [
    {
      id: Steps.LANGUAGE,
      Icon: TranslationIcon,
      title: t('language'),
      body: (
        <div className="grid gap-2">
          <div className="text-[1rem] leading-none">
            {t('selected_report_language')}
          </div>
          <LanguageRadioButtons
            selectedLocale={selectedLocale}
            setSelectedLocale={setSelectedLocale}
          />
        </div>
      ),
      nextButton: ({ onClick, }: {
        onClick: (
          event: React.MouseEvent<HTMLButtonElement, MouseEvent>
        ) => void;
      }) => (
        <div className={'flex items-center gap-8'}>
          {priceHubbleData && (
            <div className={'flex justify-end'}>
              <LimitItem
                total={priceHubbleData.priceHubbleLimit}
                value={priceHubbleData.totalUsedPriceHubble}
                isSmall
              />
            </div>
          )}
          <Button
            className="cursor-pointer gap-6 shrink-0"
            variant={'contained'}
            disableElevation
            sx={{
              height: '40px',
            }}
            onClick={(event) => {
              event.preventDefault(); // Prevent form submission
              onClick(event);
            }}
          >
            <span>{t('common:next')}</span>
            <ArrowForwardIosIcon
              sx={{
                width: '16px',
                height: '16px',
              }}
              fontSize={'small'}
            />
          </Button>
        </div>
      )
    },
    {
      id: Steps.SETTINGS,
      Icon: SettingsIcon,
      title: t('settings'),
      body: (
        <>
          <PriceHubbleReportSettings
            settings={settings}
            initialSettings={initialSettings}
            radioSelection={radioSelection}
            setRadioSelection={setRadioSelection}
            setSettings={setSettings}
          />
        </>
      ),
      nextButton: ({
        onClick,
      }: {
        onClick: (
          event: React.MouseEvent<HTMLButtonElement, MouseEvent>
        ) => void;
      }) => (
        <div className={'flex items-center gap-8'}>
          {priceHubbleData && (
            <LimitItem
              total={priceHubbleData.priceHubbleLimit}
              value={priceHubbleData.totalUsedPriceHubble}
              isSmall
            />
          )}
          <Button
            className="cursor-pointer gap-6 shrink-0"
            variant={'contained'}
            disableElevation
            sx={{
              height: '40px',
            }}
            onClick={(event) => {
              event.preventDefault(); // Prevent form submission
              onClick(event);
            }}
          >
            <span>{t('generate')}</span>
            <UpdateIcon color="currentcolor" />
          </Button>
        </div>
      ),
    },
    {
      id: Steps.REPORT,
      Icon: ArticleOutlinedIcon,
      title: t('report'),
      body: (
        <div className="flex gap-8 flex-col items-center justify-center min-h-[300px]">
          {isGenerating ? (
            <>
              <CircularProgress size={100} />
              <span>{t('Your_report_is_being_generated_Please wait')}</span>
            </>
          ) : (
            <>
              {isGenerated ? (
                <>
                  <SaveAltIcon sx={{ color: 'green', fontSize: '3rem' }} />
                  <span>{t('Your_report_is_generated_successfully')}</span>
                </>
              ) : null}
            </>
          )}
        </div>
      ),
      nextButton: () =>
        isGenerated ? (
          <Button
            className="cursor-pointer gap-6"
            variant={'contained'}
            disableElevation
            sx={{
              height: '40px',
            }}
            onClick={onClose}
          >
            <span>{t('common:close')}</span>
          </Button>
        ) : (
          <div />
        ),
    },
  ];

  if (!isValid) {
    steps.unshift({
      id: Steps.REQUIREMENTS,
      title: t('requirements'),
      Icon: InfoOutlinedIcon,
      body: <PriceHubbleReportRequirements validation={validation} />,
      nextButton: () => <div />,
    });
  }

  if (priceHubbleData && priceHubbleData.totalUsedPriceHubble >= priceHubbleData.priceHubbleLimit) {
    steps.unshift({
      id: Steps.REQUIREMENTS,
      title: t('requirements'),
      Icon: InfoOutlinedIcon,
      body: <PriceHubbleReportLimit priceHubbleData={priceHubbleData} />,
      nextButton: () => <div />,
    });
  }

  return (
    <Box
      sx={{
        '& .MuiBox-root': {
          padding: '0 !important',
        },
      }}
    >
      <Modal open={isModalOpen} onClose={onClose}>
        <ClickAwayListener onClickAway={onClickAway}>
          <Fade in={isModalOpen}>
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                maxHeight: '95vh',
                // overflowY: 'auto',
                width: {
                  xs: '90%',
                  md: '90%',
                },
                borderRadius: 2,
                bgcolor: 'background.paper',
                boxShadow: 24,
              }}
            >
              <PopupStepper
                onClose={isGenerating ? undefined : onClose}
                onNext={handleNext}
                disablePrev={shouldDisablePrev}
                steps={steps}
              />
            </Box>
          </Fade>
        </ClickAwayListener>
      </Modal>
    </Box>
  );
};

export default PriceHubbleReportPopup;
