import { GridColDef } from '@mui/x-data-grid-pro';
import CheckIcon from '@mui/icons-material/Check';
import { Box, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';

import {
  formatDate,
  renderFullPropertyAdress,
  renderPropertyAdress,
} from '../../helpers/utils';
import PermissionIcon from '../../features/icons/permissionIcon';
import HomeIcon from '../../features/icons/homeIcon';
import BedroomIcon from '../../features/icons/bedroomIcon';
import SquareIcon from '../../features/icons/squareIcon';
import MountainIcon from '../../features/icons/mountainIcon';
import { EnsureShortLocale } from '../common/ensureShortLocale';
import CreatedUpdatedHeader from './createdUpdatedHeader';
import CreatedUpdated from './createdUpdated';
import { t } from 'i18next';
import PropertyContactOptimized from './propertyContactOptimized';
import PropertyPrice from './propertyPrice';
import PropertyMainImage from './propertyMainImage';

const currentLanguage = localStorage.getItem('i18nextLng') || 'en';

export const propertiesMatchingListingConfig: GridColDef[] = [
  {
    field: 'media',
    minWidth: 250,
    maxWidth: 356,
    flex: 1,
    sortable: false,
    disableColumnMenu: true,
    renderHeader: () => {
      const [Property_Title, Reference, Status] = [
        t('common:Property_title'),
        t('common:Reference'),
        t('common:Status'),
      ];

      return (
        <span className="flex flex-col">
          <span className="leading-5 font-medium"> {Property_Title} </span>
          <span className="leading-5 font-medium">
            {Reference}, {Status}
          </span>
        </span>
      );
    },
    renderCell: ({ row }) => {
      const lang = `${EnsureShortLocale(currentLanguage)}`;
      return (
        <div className={'flex items-start w-[90%] gap-x-2'}>
          <div className={'py-2 w-2/5 shrink-0'}>
            <PropertyMainImage row={row} />
          </div>
          <div className={'flex flex-col pt-1 pb-2 w-[70%]'}>
            {row.translations &&
            row.translations[lang] &&
            row.translations[lang].title ? (
              <Tooltip
                title={row.translations[lang].title}
                arrow
                placement="top"
              >
                <Link
                  className={'text-base inline-flex'}
                  to={`/properties/${row.id}`}
                  id={`img_property_${row.id}`}
                >
                  {' '}
                  <p className={'font-normal truncate w-[100%]'}>
                    {row.translations[lang].title}
                  </p>
                </Link>
              </Tooltip>
            ) : null}
            <Box className="py-2">
              <div
                className="flex py-1 px-2 mb-2 rounded-md items-center"
                style={{ border: '1px solid #3446B9 ' }}
              >
                <PermissionIcon className="mr-2 col-span-1" color="#3446B9" />{' '}
                <span className="text-blue leading-5">{row.reference}</span>
              </div>
              <div
                className="flex py-1 px-2 rounded-md justify-center"
                style={{ border: '1px solid #4CAF50' }}
              >
                <span className="text-[#4CAF50] leading-5 capitalize text-center">
                  {row?.status?.translations[lang]?.value}
                </span>
              </div>
            </Box>
          </div>
        </div>
      );
    },
  },
  {
    field: 'category',
    minWidth: 180,
    maxWidth: 210,
    flex: 1,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    renderHeader: () => {
      const [Property_type, Location] = [
        t('common:Property_type'),
        t('common:Location'),
      ];
      return (
        <span className="flex flex-col">
          <span className="leading-5 font-medium">{Property_type}</span>
          <span className="leading-5 font-medium">{Location}</span>
        </span>
      );
    },
    renderCell: ({ row }) => {
      const lang = `${EnsureShortLocale(currentLanguage)}`;
      return (
        <Box className="flex flex-col gap-2 w-full">
          <div>
            {row.type && (
              <span className="inner-tag leading-5 capitalize bg-gray-100 rounded py-1 px-2">
                {row.type?.translations && row.type?.translations.length
                  ? `${row.type?.translations[lang]?.value}`
                  : row.type.keyname}
              </span>
            )}
          </div>
          <Tooltip title={renderFullPropertyAdress(row)} arrow placement="top">
            {renderPropertyAdress(row, true)}
          </Tooltip>
        </Box>
      );
    },
  },
  {
    field: 'email',
    minWidth: 160,
    maxWidth: 200,
    flex: 1,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    headerName: 'Email',
    renderCell: ({ row }) => {
      const [Sent] = [t('common:Sent')];
      return (
        <div className={'flex items-center'}>
          {row.sentToClient ? (
            <div
              className={
                'flex flex-col justify-start items-start gap-2 h-full py-4'
              }
            >
              <div
                className="flex py-1 px-2 rounded-md items-center text-[#4CAF50]"
                style={{ border: '1px solid #4CAF50' }}
              >
                <CheckIcon fontSize={'small'} />
                <span className="leading-5 capitalize">{Sent}</span>
              </div>
              <div className="inner-tag h-6 px-2 py-1 bg-neutral-100 rounded justify-start items-center gap-1 inline-flex">
                <div className="text-zinc-900 text-sm font-normal font-['Inter'] leading-none">
                  {formatDate(row.sentToClientAt)}
                </div>
              </div>
            </div>
          ) : null}
        </div>
      );
    },
  },
  {
    field: 'displayedPrice',
    headerName: 'Price',
    flex: 1,
    minWidth: 130,
    maxWidth: 160,
    disableColumnMenu: true,
    sortable: false,
    filterable: false,
    renderCell: ({ row }) => {
      return <PropertyPrice row={row} />;
    },
  },
  {
    field: 'rooms',
    headerName: 'Dimensions',
    flex: 1,
    minWidth: 145,
    maxWidth: 165,
    disableColumnMenu: true,
    sortable: false,
    filterable: false,
    renderCell: ({ row }) => (
      <Box className="flex justify-center gap-x-8 w-full">
        <Box className="flex flex-col gap-2">
          <Tooltip title={`${t('common:Rooms')}`} arrow placement="top">
            <Box className="flex" sx={{ lineHeight: '20px' }}>
              <HomeIcon className="mr-2 col-span-1" color="#6E6E72" />{' '}
              <span>{row.rooms}</span>
            </Box>
          </Tooltip>
          <Tooltip title={`${t('common:Bedrooms')}`} arrow placement="top">
            <Box className="flex" sx={{ lineHeight: '20px' }}>
              <BedroomIcon className="mr-2 col-span-1" color="#6E6E72" />{' '}
              <span>{row.bedrooms}</span>
            </Box>
          </Tooltip>
        </Box>
        <Box className="flex flex-col gap-2">
          <Tooltip title={`${t('common:Living_area')}`} arrow placement="top">
            <Box className="flex" sx={{ lineHeight: '20px' }}>
              <SquareIcon className="mr-2 col-span-1" color="#6E6E72" />{' '}
              <span>
                {(row.addFromToLivingArea
                  ? t('propertiesPage:from') + ' '
                  : '') +
                  (row.useTildeForSurface ? '~' : '') +
                  row.livingArea}
              </span>
            </Box>
          </Tooltip>
          <Tooltip title={`${t('common:Land_Surface')}`} arrow placement="top">
            <Box className="flex" sx={{ lineHeight: '20px' }}>
              <MountainIcon className="mr-2 col-span-1" color="#6E6E72" />{' '}
              <span>
                {row.landSurface
                  ? (row.addFromToLandSurface
                      ? t('propertiesPage:from') + ' '
                      : '') +
                    (row.useTildeForSurface ? '~' : '') +
                    row.landSurface
                  : '-'}
              </span>
            </Box>
          </Tooltip>
        </Box>
      </Box>
    ),
  },
  {
    field: 'mainBroker',
    headerName: 'Contacts',
    flex: 1,
    minWidth: 140,
    maxWidth: 170,
    sortable: false,
    disableColumnMenu: true,
    filterable: false,
    renderCell: ({ row }) => {
      return <PropertyContactOptimized row={row} />;
    },
  },
  {
    field: 'createdAt',
    headerName: 'date',
    // maxWidth: 120,
    minWidth: 190,
    flex: 1,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    renderHeader: () => <CreatedUpdatedHeader />,
    renderCell: ({ row }) => (
      <CreatedUpdated created={row.createdAt} updated={row.updatedAt} />
    ),
  },
];
