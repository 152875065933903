import CancelIcon from '@mui/icons-material/Cancel';
import Modal from '@mui/material/Modal';
import CheckIcon from '@mui/icons-material/Check';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Props {
  messageColor: string;
  message: string;
  open: boolean;
  handleClose: () => void;
}

const ExportPopup = (props: Props) => {
  const { open, handleClose, messageColor, message } = props;

  const { t } = useTranslation('propertiesPage');

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={(theme) => ({
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          borderRadius: '7px',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 2,
          color: messageColor,
          width: '88%',
          height: '29%',
          [theme.breakpoints.up(640)]: {
            width: '67%',
          },
          [theme.breakpoints.up(768)]: {
            width: '59%',
          },
          [theme.breakpoints.up(1024)]: {
            width: '40%',
            height: '30%',
          },
        })}
      >
        <div className="flex flex-col sm:justify-around items-center h-full">
          <span>
            {messageColor == '#4CAF50' ? (
              <CheckIcon sx={{ color: '#4CAF50', fontSize: '48px' }} />
            ) : (
              <CancelIcon sx={{ color: 'red', fontSize: '48px' }} />
            )}
          </span>
          <span className="text-lg text-center font-medium font-[Inter]">
            {t(message)}
          </span>
        </div>
      </Box>
    </Modal>
  );
};

export default ExportPopup;
